<template>
  <component :is="layout">
    <RouterView />
  </component>
  <AppToasts />
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";


import AuthLayout from "@/layouts/authLayout.vue";
import DefaultLayout from "@/layouts/defaultLayout.vue";
import AppToasts from "@/components/app/AppToasts.vue";

const layouts = {
  auth: AuthLayout,
  default: DefaultLayout,
};

const route = useRoute();
const layout = computed(() => {
  return layouts[route.meta.layout];
});
</script>

<style lang="postcss">
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-zinc-800 dark:text-zinc-300;
}

p,
div,
span,
strong,
u,
b,
small,
li {
  @apply text-zinc-700 dark:text-zinc-50;
}

body {
  font-family: "Nunito Sans", sans-serif;
}

main table {
  a:link:not(.btn),
  a:visited:not(.btn) {
    @apply font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400;
    transition: all 0.2s;
    text-underline-offset: 4px;
    text-decoration: underline;
  }
}
</style>
