<template>
	<Teleport to="body">
	  <!-- Backdrop outside of the transition -->
	  <div v-if="modalStore.isOpen" class="modal-backdrop" @click="modalStore.closeModal()"></div>

	  <!-- Dialog inside the transition -->
	  <Transition name="fade">
		<div v-if="modalStore.isOpen" class="modal-dialog transition-opacity" :class="modalStore.size">
		  <div class="modal-header">
			<slot name="header">
			  <slot name="modal-title">
				<h3>{{ modalStore.title }}</h3>
			  </slot>
			  <BaseButton icon="close" size="xs" variant="secondary" @click="modalStore.closeModal()" />
			</slot>
		  </div>
		  <div class="modal-body">
			<component v-if="modalStore.component" :is="modalStore.component" v-bind="modalStore?.props" @close-modal="modalStore.closeModal()" />
		  	<div v-if="!modalStore.component && modalStore.content" v-html="modalStore.content"></div>
		  </div>
		</div>
	  </Transition>
	</Teleport>
  </template>

  <script setup>
  import { useModalStore } from "@/stores/ModalStore";

  const modalStore = useModalStore();

  </script>

  <style lang="postcss" scoped>
  .modal-backdrop {
	@apply fixed z-40 top-0 left-0 right-0 bottom-0 bg-black/30;
	backdrop-filter: blur(8px);
  }

  .modal-dialog {
	@apply fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-zinc-800 rounded-xl shadow-md flex flex-col border border-zinc-200 dark:border-zinc-600;

	max-width: calc(100vw - 2rem);
	max-height: calc(100vh - 2rem);
	overflow: auto;

	&.sm {
	  width: 300px;
	}

	&.md {
	  width: 600px;
	}

	&.lg {
	  width: 1000px;
	}

	&.xl {
	  width: 1200px;
	}

	.modal-header {
	  @apply flex justify-between items-center -mt-1 p-4 pt-3 bg-gradient-to-b from-60% from-white dark:from-zinc-800;
	  position: sticky;
	  top: -1px;
	  z-index: 2;

	  h3 {
		@apply text-lg font-bold;
	  }

	  .material-symbols-rounded {
		font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;

		@apply rounded-full p-1 cursor-pointer -mr-2;

		&:hover {
		  @apply bg-zinc-100;
		}

		&:active {
		  @apply bg-zinc-200;
		}
	  }
	}

	.modal-body {
	  @apply p-4 pt-0;
	}
  }

  /* Transition for modal dialog only */
  .fade-enter-active, .fade-leave-active {
	transition: opacity 0.3s ease;
  }
  .fade-enter-from, .fade-leave-to {
	opacity: 0;
  }
  .fade-enter-to, .fade-leave-from {
	opacity: 1;
  }
  </style>
