import * as yup from 'yup';
import { serverPortSchema } from './common';


const monitorIntervalBaseSchema = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .min(20)
  .max(60)
  .nullable(true);

const monitorMaxTimeBaseSchema = yup
  .number()
  .min(0)
  .max(50)
  .nullable(true)
  .default(0)
  .transform((value, originalValue) => {
    if (originalValue === '') return null;
    return value;
  });

const monitorCheckToFailBaseSchema = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .min(3)
  .max(10)
  .nullable(true);

const monitorAllowedStatesBaseSchema = yup.lazy((val) =>
    Array.isArray(val)
      ? yup.array().of(yup.string().matches(/^(\d+(,\d+)*)?$/))
      : yup.string().matches(/^(\d+(,\d+)*)?$/)
);

const monitorSchema = yup.object({
  type: yup.string().required(),
  port: serverPortSchema,
  needKeyword: yup.string().nullable(true),
  notNeedKeyword: yup.string().nullable(true),
  maxTime: monitorMaxTimeBaseSchema,
  interval: monitorIntervalBaseSchema,
  checkToFail: monitorCheckToFailBaseSchema,
  allowedStates: monitorAllowedStatesBaseSchema,
});


export default monitorSchema;

export {
  monitorIntervalBaseSchema,
  monitorMaxTimeBaseSchema,
  monitorCheckToFailBaseSchema,
  monitorAllowedStatesBaseSchema
}