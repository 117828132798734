<template>
  <div class="px-4 h-screen bg-neptune-50 w-full dark:bg-zinc-900">
    <div class="container h-full mx-auto">
      <div class="h-full w-full flex flex-col items-center justify-center gap-8">
        <AppLogo :mode="userStore.mode == 'light' ? 'dark' : 'light'" class="w-64" />
        <BaseBox
          class="w-full max-w-lg flex flex-col items-center justify-center space-y-4 p-4 bg-white"
        >
          <slot></slot>
        </BaseBox>
        <div v-if="currentRoute.name === 'LoginPage'">
          {{ $t("text_lost_credentials") }}
          <RouterLink
            class="underline underline-offset-2 font-semibold"
            :to="{ name: 'RecoveryPage' }"
            >{{ $t("label_get_new") }}</RouterLink
          >
        </div>
        <div v-else-if="currentRoute.name === 'RecoveryPage'">
          {{ $t("text_remember_password") }}
          <RouterLink
            class="underline underline-offset-2 font-semibold"
            :to="{ name: 'LoginPage' }"
            >{{ $t("label_login") }}</RouterLink
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/UserStore";
const userStore = useUserStore();

import AppLogo from "@/components/ui/AppLogo.vue";
import BaseBox from "@/components/ui/BaseBox.vue";

const route = useRoute();

const currentRoute = computed(() => {
  return route;
});

onMounted(() => {
  if(!userStore.mode ) document.documentElement.classList.add("dark");

  if (userStore.mode == "dark") {
    document.documentElement.classList.add("dark");
  }
});
</script>
