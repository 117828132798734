<template>
<div class="flex flex-col w-full relative">
  <div class="flex justify-between gap-4 w-full" style="position: absolute">
    <div class="flex-1 flex justify-start gap-4 basis-2/4	">
      <PageTitle />
      <SearchBar class="custom-searchbar"  @on-search="handleSearch" />
      <div v-if="checkPermission('read_monitor_list')" class="flex items-center gap-2">
        Status:
        <BaseSelect v-model="searchStatus" @change="handleSearch" name="searchStatus" :options="searchOptions">
        </BaseSelect>
      </div>
      <div v-if="organizationOptions.length > 0" class="flex items-center gap-2">
        {{ $t('label_organization') }}:
        <BaseSelect v-model="searchOrganization" name="searchOrganization" :options="[
          {
            value: '',
            label: t('label_all'),
          },
          ...organizationOptions,
        ]"></BaseSelect>
      </div>

    </div>
    <div class="flex-1 flex justify-end">
        <BaseButton class="mt-0 flex-start" size="sm" @click="router.go(-1)"> {{ $t('label_go_back') }} </BaseButton>
    </div>
  </div>

  <div class="monitor-list-wrapper justify-between mt-4" style="width: 100%; margin-top: 1.25rem;">
    <template v-if="monitorList.length > 0">
      <BaseBox v-for="(monitor, index) in filteredMonitors" :key="monitor.id" :class="[{'!bg-red-500/10 dark:!bg-red-800/10 !border-red-500/50 dark:!border-red-700/50': monitor.status == 'unhealthy'}, index > 0 ? 'mt-4' : '']">
        <div class="flex md:flex-wrap items-end gap-x-4">
          <div class="grow space-y-1">
            <span class="flex gap-2">
              <h3 class="font-semibold">
                <component :is="monitor.zoneID || monitor.poolID ? 'router-link' : 'span'" :to="getToUrl(monitor)"
                  :class="{ 'font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400': monitor.zoneID || monitor.poolID }">
                  {{ getMonitorReferenceName(monitor) + ': ' + monitor.name }}
                </component>
              </h3>
            </span>
            <span class="flex gap-2">
              <h3 class="font-semibold">
                <component :is="monitor.zoneID || monitor.poolID ? 'router-link' : 'span'"
                  :to="`/zones/${monitor.zoneID}`"
                  :class="{ 'font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400': monitor.zoneID || monitor.poolID }">
                  {{ ($t('title_domain')).toUpperCase() + ' ' + getMonitorDomain(monitor) }}
                </component>
              </h3>
            </span>
            <h4>ID: {{ monitor.id }}</h4>
            <template v-if="monitor.status == 'unhealthy'">
              <BaseTag variant="danger" :text="$t('label_unhealthy')" icon="close" />
            </template>

            <template v-else-if="monitor.status == 'healthy'">
              <BaseTag variant="success" :text="$t('label_healthy')" icon="done" />
            </template>

            <template v-else-if="monitor.status == null">
              <BaseTag variant="secondary" :text="$t('label_not_active')" icon="equal" />
            </template>
          </div>
          <div class="grow" v-if="monitor.statusDetails.lastEvent">
            <div class="text-sm">{{ $t('title_last_down') }}</div>
            <div class="text-lg">
              {{ formatTime(monitor.statusDetails.lastEvent) }}
            </div>
          </div>
          <div v-if="monitor.statusDetails.uptime.daily !== null" class="grow">
            <div class="text-sm">24H Uptime</div>
            <div class="text-lg">{{ monitor.statusDetails.uptime.daily.toFixed(2) }}%</div>
          </div>
          <div v-if="monitor.statusDetails.uptime.monthly !== null" class="grow">
            <div class="text-sm">30D Uptime</div>
            <div class="text-lg">{{ monitor.statusDetails.uptime.monthly.toFixed(2) }}%</div>
          </div>
          <div v-if="monitor.statusDetails.uptime.annual !== null" class="grow">
            <div class="text-sm">1Y Uptime</div>
            <div class="text-lg">{{( monitor.statusDetails.uptime.annual.toFixed(2)) }}%</div>
          </div>
          <div class="grow space-y-2">
            <StatusBars v-if="monitor.statusDetails.uptimeDetails.daily" label="24H" :data="monitor.statusDetails.uptimeDetails.daily" :unit="'h'"/>
            <StatusBars v-if="monitor.statusDetails.uptimeDetails.monthly" label="30D" :data="monitor.statusDetails.uptimeDetails.monthly" />
          </div>
          <div class="grow flex flex-col items-end gap-y-2">
            <div class="flex items-center gap-x-2">
              <BaseButton 
                v-if="checkPermission('edit_monitor_single')" 
                icon="edit" tooltip="Edit" size="xs" variant="secondary"
                @click="handleEditMonitor(monitor)" 
              />
              <div v-if="monitor.recordID && checkPermission('change_monitor_status')">
                <BaseButton v-if="!monitor.enabled" size="sm" variant="primary" @click="toggleEnableDisable(monitor)" >{{ ($t('label_restart')).toUpperCase() }}</BaseButton>
                <BaseButton v-else size="sm" variant="danger" @click="toggleEnableDisable(monitor)" >{{ ($t('label_pause')).toUpperCase() }}</BaseButton>
              </div>
            </div>    
            <div>
              <BaseButton 
                v-if="checkPermission('export_monitor_events_log')"
                size="sm"
                tag="RouterLink" 
                variant="primary" 
                target="_blank"
                type="text" 
                :to="{ name: 'MonitorMetricsPage', params: { id: monitor.id } }"
              >
                {{ $t('label_export_log_event') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseBox>
    </template>

    <template v-else>
      <BaseBox class="mt-4">
        <h3 class="font-semibold">{{ $t('text_no_monitor') }}</h3>
      </BaseBox>
    </template>
  </div>

</div>


</template>

<script setup>
import { markRaw, computed, ref } from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import router from "@/routes";

import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useMonitorsStore } from "@/stores/MonitorsStore";
import { useModalStore } from "@/stores/ModalStore";
import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { useZonesStore } from "@/stores/ZonesStore";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import MonitorForm from "@/components/monitors/MonitorForm.vue";
import StatusBars from "@/components/ui/StatusBars.vue";
import { useAuthStore } from "../stores/AuthStore";
import { translate as t } from "@/i18n";
import useToastStore from "@/stores/toast";
import { capitalizeFirst } from "@/utilities/helpers";


const { checkPermission } = useAuthStore();
const monitorsStore = useMonitorsStore();
const zonesStore = useZonesStore();
const modalStore = useModalStore();
const organizationsStore = useOrganizationsStore();
const { addToast } = useToastStore();

const { monitors, getMonitorsById } = storeToRefs(monitorsStore);
const route = useRoute();
const monitorForm = markRaw(MonitorForm);

const searchOptions = ref([
  {
    value: '',
    label: t('label_all'),
  },
  {
    value: 'healthy',
    label: t('label_healthy'),
  },
  {
    value: 'unhealthy',
    label: t('label_unhealthy'),
  },
  {
    value: null,
    label: t('label_not_active'),
  },
])
const searchValue = ref("");
const searchStatus = ref("");
const searchOrganization = ref("");

const monitorList = computed(() => {
  if (route?.params?.id) {
    return getMonitorsById.value(route.params.id);
  } else {
    return monitors.value;
  }
});

const organizationOptions = computed(() =>
  organizationsStore.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }))
);

const filteredMonitors = computed(() => {

  const searchString = (typeof searchValue.value === 'string') ? searchValue?.value?.toLowerCase() : '';
  const selectedStatus = (searchStatus.value === null) ? null : searchStatus.value.toLowerCase();

  return monitorList.value.filter(monitor => {

    const matchesSearch = searchString === '' ? true : (getMonitorDomain(monitor))?.toLowerCase().includes(searchString) || monitor.name.toLowerCase().includes(searchString);

    const matchesStatus = selectedStatus === '' || monitor.status === selectedStatus;

    return matchesSearch && matchesStatus;
  });

});

function getMonitorReferenceName(monitor) {
  if (monitor?.recordID) {
    return 'RECORD'
  }
  else if (monitor?.poolID && monitor?.endpointPoolID) {
    return 'ENDPOINT'
  }
  else if (monitor?.clusterID && monitor?.popCode) {
    return 'POP'
  }
  else return ''
}
function getToUrl(monitor) {
  if (monitor?.recordID) {
    return `/zones/${monitor.zoneID}#${monitor.recordID}`
  }
  else if (monitor?.poolID) {
    return `/zones/${monitor.zoneID}/pool/${monitor.poolID}/edit/#${monitor.endpointPoolID}`
  }
  else if (monitor?.clusterID && monitor?.popCode) {
    return `/cluster/${monitor.clusterID}`;
  }
  else return ''
}

function getMonitorDomain(monitor) {
  const zone = zonesStore.zones.find((zone) => zone.id === monitor.zoneID);
  if (zone?.domain) return zone.domain;
}

function handleEditMonitor(monitor) {
  modalStore.openModal({
    title: t('title_edit_monitor') + ' ' + getMonitorReferenceName(monitor),
    component: monitorForm,
    props: {
      id: monitor.id,
      isEditing: true,
      initialValues: monitor,
    },
  });
}

const error = ref("");

async function toggleEnableDisable(monitor) {
  try {
    if(monitor.enabled){
    await modalStore.openConfirmModal({
        title: t('title_disable_monitor'),
        component: ConfirmModal,
        props: {
          error,
          content: t('text_disable_monitor'),
        },
      });
    }
    const toUpdate = {
      ...monitor,
      enabled: !monitor.enabled, 
    }


    await monitorsStore.updateMonitor(toUpdate, monitor.id);

    await zonesStore.refreshData(monitor.zoneID);

    addToast({
      title: t('text_update_generic_success'),
      variant: "success",
    });
    monitor.enabled = !monitor.enabled;
    
  } catch (error) {
    console.error(error);
    if(error)
      addToast({
        title: capitalizeFirst(t("text_update_failure") ),
        variant: "danger",
      });
  }
  finally {
    error.value = "";
    modalStore.closeModal();
  }
}

function formatTime(dateToCompare) {
  const now = dayjs();
  const pastDate = dayjs(dateToCompare);
  const units = [
    { name: "year", singular: "text_year_ago", plural: "text_years_ago", threshold: 365 },
    { name: "month", singular: "text_month_ago", plural: "text_months_ago", threshold: 30 },
    { name: "day", singular: "text_day_ago", plural: "text_days_ago", threshold: 1 },
    { name: "hour", singular: "text_hour_ago", plural: "text_hours_ago", threshold: 60 },
    { name: "minute", singular: "text_minute_ago", plural: "text_minutes_ago", threshold: 60 },
    { name: "second", singular: "text_second_ago", plural: "text_seconds_ago", threshold: 60 },
  ];
  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    const { name, singular, plural } = unit;
    const diff = now.diff(pastDate, name);

    // Se troviamo una differenza significativa, restituiamo il risultato
    if (diff >= 1) {
      const key = diff === 1 ? singular : plural;
      return t(key, { num: diff });
    }
  }


  // Fallback: se nessuna differenza è rilevante, restituisci "adesso"
  return t("text_now");

}

function handleSearch(value) {
  searchValue.value = value;
}

</script>
<style scoped lang="postcss">
.monitor-list-wrapper {
  overflow: scroll;
  position: absolute;
  width: calc(100% - 2.5rem);
  margin: 0;
  top: 2.5rem;
  height: calc(100vh - 8.5rem);

  &::-webkit-scrollbar {
    display: none;
  }
}
.custom-searchbar {
  @apply w-80;

  :deep(.search-input){
      height: 100%;

  }

  :deep(div) {
      height: 100%;
  }

  :deep(div > .search-input) {
      height: 100%;
  }

}
</style>