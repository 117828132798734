<template>
  <div class="space-y-4">
    <p>{{ $t('text_delete_zone_confirm') }}</p>
    <p v-html="$t('text_delete_zone_confirm_desc', {delete: textForDelete})"></p>
    <BaseInput name="delete" @update:value="(val) => (value = val)" :value="value" />
    <div class="flex gap-x-4 justify-center">
      <BaseButton @click="handleDelete()" :disabled="value !== textForDelete">{{ textForDelete }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ $te(error) ? $t(error) : error }}</AlertMessage>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import router from "@/routes";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const { addToast } = useToastStore();

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  id: Number,
});

const error = ref("");
const value = ref("");

const zoneStore = useZonesStore();


const textForDelete = computed(() => {
  return t('text_delete_title').toUpperCase();
});

async function handleDelete() {
  try {
    await zoneStore.deleteZone(props.id);
    router.push({ name: "ZonesPage" });
    addToast({
      title: t('text_delete_zone_success'),
      variant: "success",
    });
  } catch (err) {
    error.value = err;
    if(err)
      addToast({
        title: t('text_delete_zone_error'),
        variant: "danger",
      });
  }
  finally{
    emit("closeModal");
  }
}
</script>
