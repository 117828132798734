<template>
    <div>
        <Table :data="disks">
        <template #head>
            <tr>
                <th v-for="field in fields" :key="field">{{ $t(`label_${field}`) }}</th>
            </tr>
        </template>
        <template #body>
            <template v-for="item in data" :key="item[fields[0]]">
            <tr>
                <td v-for="field in fields" :key="field" >
                {{ item[field]}}
                </td>
            </tr>
            </template>
        </template>
        </Table>
    </div>
</template>

<script setup>
const props = defineProps({
    data: Array,
    fields: Array
})

const {disks} = props

</script>

<style lang="scss" scoped>

</style>