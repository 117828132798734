<template>
  <div class="page-title">
    <slot name="page-title">
      <div class="flex items-center gap-2">
        <div class="flex items-center gap-2" v-for="(breadcrumb, index) in previousPages" :key="index">
          <RouterLink :to="breadcrumb.to" class="previous">
            <h2>
              {{ breadcrumb.text ?$t(breadcrumb.text) : '' }}
            </h2>
          </RouterLink>
          <div class="divider" v-if="previousPages.length - 1 !== index">/</div>
        </div>
      </div>
      <div class="flex items-center gap-3">
        <h1>
          {{  currentPage.text ? $t(currentPage.text) : '' }}
        </h1>
        <slot name="title-info"> </slot>
      </div>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  titles: {
    type: Array,
    default: [],
  },
  type: {
    type: String
  }
});

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";

if (props.type == 'zone') {
}
const zoneStore = useZonesStore();
const { zone } = storeToRefs(zoneStore);

const route = useRoute();

const breadcrumbs = computed(() => {
  if (typeof route.meta.breadcrumb === "function") {
    return route.meta.breadcrumb.call(this, route);
  }
  return route.meta.breadcrumb;
});

const previousPages = ref([]);
const currentPage = ref({});

function setBreadcrumbs() {
  currentPage.value = breadcrumbs.value[breadcrumbs.value.length - 1];

  if (props.type == 'zone') {
    currentPage.value.text = zone?.value?.domain || '';
  }

  previousPages.value = breadcrumbs.value.slice(0, -1);
}

setBreadcrumbs();

watch(breadcrumbs, () => {
  setBreadcrumbs();
});

watch(zone, () => {
  setBreadcrumbs();
});
</script>

<style lang="postcss" scoped>
.previous {
  h2 {
    @apply text-lg font-medium;
  }

  &:hover {
    h2 {
      @apply text-neptune-500 dark:text-neptune-300;
    }

    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    text-decoration-color: theme("colors.neptune.300");
  }


}

h1 {
  @apply text-2xl font-bold;
}
</style>
