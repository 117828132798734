import * as yup from 'yup';
import { passwordBaseSchema, emailBaseSchema } from './common';



const changePasswordSchema = yup.object({
    previusPassword: yup.string().required(),
    userPassword: passwordBaseSchema.required(),
    userConfirmPassword: yup.string().passwordMatch('userPassword')
});


const resetPasswordSchema = yup.object({
  userPassword: passwordBaseSchema.required(),
  userConfirmPassword: yup.string().passwordMatch('userPassword')
      
});


const userLoginSchema = yup.object({
  userEmail: emailBaseSchema.required(),
  userPassword: yup.string().required(),
});

const recoveryPageSchema = yup.object({
  userEmail: emailBaseSchema.required(),
});

export {
  userLoginSchema,
  passwordBaseSchema,
  changePasswordSchema,
  resetPasswordSchema,
  recoveryPageSchema
}