<template>
	<form class="space-y-4" @submit="onSubmit">
		<!-- Name and CustomerID -->
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="name" :label="$t('label_name')" name="name" :placeholder="$t('placeholder_name')" type="text" required />
			<BaseInput id="zoho_customer_id"
                       :label="$t('label_zoho_customer_id')"
                       name="zoho_customer_id"
                       :placeholder="$t('placeholder_zoho_customer_id')"
                       type="text"
                       required
            />
		</div>

		<!-- Emails and Phone -->
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="phone" :label="$t('label_phone')" name="phone" :placeholder="$t('placeholder_phone')" type="text" required />
			<BaseInput id="emailAlert" :label="$t('label_email_alert')" name="emailAlert" :placeholder="$t('placeholder_email_alert')" type="email" required />
			<BaseInput id="emailBilling" :label="$t('label_email_billing')" name="emailBilling" :placeholder="$t('placeholder_email_billing')" type="email" required />
		</div>

		<!-- Address -->
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="address.street" :label="$t('label_street')" name="address.street" :placeholder="$t('placeholder_street')" type="text" required />
			<BaseInput id="address.city" :label="$t('label_city')" name="address.city" :placeholder="$t('placeholder_city')" type="text" required />
			<BaseInput id="address.state" :label="$t('label_state')" name="address.state" :placeholder="$t('placeholder_state')" type="text" required />
			<BaseInput id="address.postalCode" :label="$t('label_postal_code')" name="address.postalCode" :placeholder="$t('placeholder_postal_code')" type="text" required />
		</div>

		<!-- Save Button -->
		<div class="text-right mt-4">
			<BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ isEditing ? $t("label_update") : $t("label_add") }}</BaseButton>
		</div>
	</form>
</template>

<script setup>
import { useForm } from "vee-validate";
import { capitalizeFirst } from "@/utilities/helpers";
import { i18n } from "@/i18n";
const { t } = i18n.global;

import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import useToastStore from "@/stores/toast";
import { onMounted, ref } from "vue";
import organizationSchema from "@/validations/schemes/organization";

const {initialValues, isEditing}  = defineProps({
  initialValues: {
    type: Object,
    default: () => ({}),
  },
  isEditing:{
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["closeModal"]);

const organizationStore = useOrganizationsStore();
const { addToast } = useToastStore();
const { createOrganization, updateOrganization } = organizationStore;

const error = ref("");

const schema = organizationSchema

const { isSubmitting, handleSubmit, meta, validate } = useForm({
    validationSchema: schema,
    initialValues:  initialValues || {
        _id: '',
        name: '',
        emailAlert: '',
        emailBilling: '',
        phone: '',
        zoho_customer_id: '',
        address: {
            street: '',
            city: '',
            state: '',
            postalCode: ''
        }
    },
});


onMounted(async () => {
  if(isEditing) validate(); // Triggera la validazione iniziale
});


const onSubmit = handleSubmit.withControlled(async (values) => {
    try {

        // Ensure `phone` is cast to string
        // const castValues = schema.cast(values);
        if(isEditing){
          const id = initialValues._id
          await updateOrganization(id, values);
        }
        else await createOrganization(values);

        emit("closeModal");
        const updateTitle = t("text_update_success");
        const inviteTitle = t("text_create_success");
        const title = capitalizeFirst(isEditing ? updateTitle : inviteTitle);
        addToast({
            title,
            variant: "success",
        });
    } catch (err) {
        const updateTitle = t("text_update_failure");
        const inviteTitle = t("text_create_failure");
        const title = capitalizeFirst(isEditing ? updateTitle : inviteTitle);
        addToast({
            title,
            content: error.value || err,
            variant: "danger",
        });
    }
});

</script>
