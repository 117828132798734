<template>
  <div class="space-y-4">
    <p v-html="$t('text_delete_firewall_rule_confirm')"></p>
    <BaseInput
      name="delete"
      @update:value="(val) => (value = val)"
      :value="value"
    />
    <div class="flex gap-x-4 justify-center">
      <BaseButton @click="handleDelete()" :disabled="value !== 'DELETE'"
        >{{ ($t("label_delete")).toUpperCase() }}</BaseButton
      >
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useClusterStore } from "@/stores/ClusterStore";

const emit = defineEmits(["closeModal"]);

const props = defineProps({
  clusterID: [Number, String],
  firewallID: [Number, String],
});

const error = ref("");
const value = ref("");

const clusterStore = useClusterStore();

async function handleDelete() {
  try {
    await clusterStore.deleteFirewallRule(props.clusterID, props.firewallID);
    emit("closeModal");
  } catch (err) {
    error.value = err;
  }
}
</script>
