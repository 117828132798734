<template>
	<form class="space-y-4" @submit="onSubmit">
		<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
			<BaseInput id="memory_mib" :label="$t('label_memory_unit', { unit: 'Gb' })" name="memory_mib" :placeholder="$t('placeholder_memory_unit', {unit: 'Gb'})" :min="minimums.memory_mib" type="number" required />
			<BaseInput id="storage_mib" :label="$t('label_storage_unit', { unit: 'Gb' })" name="storage_mib" :placeholder="$t('placeholder_storage_munit', {unit: 'Gb'})" :min="minimums.storage_mib" type="number" required />
			<BaseInput id="vcpu" :label="$t('label_vcpu')" name="vcpu" :placeholder="$t('placeholder_vcpu')" :min="minimums.vcpu" type="number" required />
		</div>
		<div class="text-right mt-4">
			<BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t("label_update")  }}</BaseButton>
		</div>
	</form>
</template>

<script setup>
	import { ref } from "vue";
	import { useForm } from "vee-validate";
	import useToastStore from "@/stores/toast";
	import { usePoolsStore } from "@/stores/PoolsStore";
	import { capitalizeFirst } from "@/utilities/helpers";
	import { poolContractSchema } from "@/validations/schemes/resourcePool";

	import { i18n } from "@/i18n";
	const { t } = i18n.global;
	const poolsStore = usePoolsStore();

	const { addToast } = useToastStore();

	const { pool } = poolsStore;
	const initialValues = {
		storage_mib: parseInt(pool.contract.storage_mib / 1024),
		memory_mib: parseInt(pool.contract.memory_mib / 1024),
		vcpu: pool.contract.vcpu,
	};

	const error = ref("");
	const emit = defineEmits(["closeModal"]);


	const schema = poolContractSchema(initialValues);
	const isSubmitting = ref(false);
	const minimums = {...initialValues};

	const { handleSubmit, meta } = useForm({
		validationSchema: schema,
		initialValues
	});

	const onSubmit = handleSubmit.withControlled(async (values) => {
		try {

			const castValues = schema.cast(values);
			isSubmitting.value = true;
			const toUpdate = { 
				contract: {
					memory_mib: castValues.memory_mib * 1024,
					storage_mib: castValues.storage_mib * 1024,
					vcpu: castValues.vcpu
				}, 
				name: pool.name, 
				poolCode: pool.poolCode
			}

			await poolsStore.updatePool(toUpdate, pool.id);
			
			emit("closeModal");

			addToast({
				title: capitalizeFirst(t("text_update_generic_success")),
				variant: "success",
			});
		} catch (err) {
			addToast({
				title: `${t("text_update_failure")}`,
				content: error.value || err,
				variant: "danger",
			});
		} finally {
			isSubmitting.value = false;
		}
	});
</script>
