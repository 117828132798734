<template>
  <BaseBox class="space-y-4">
    <div class="flex justify-between">
      <div class="space-y-4">
        <h2 class="text-2xl font-bold">{{ $t('title_manage_configuration') }} {{ getZone()?.domain ? $t('text_for') : '' }}
          <RouterLink v-if="getZone()" :to="`/zones/${(getZone()?.id)}`"
            class="font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400">
            {{ (getZone())?.domain ? (getZone())?.domain : '' }}
          </RouterLink>
        </h2>
        <p>{{ $t('text_manage_configuration_desc') }}</p>
      </div>
      <div>
        <BaseButton class="mt-0 flex-start" size="sm" @click="router.go(-1)"> {{ $t('label_go_back') }} </BaseButton>
      </div>
    </div>
    <form @submit.prevent="onSubmit" v-if="clusterStore.configuration" class="max-w-4xl m-auto space-y-4">
      <BaseBox v-for="configuration in clusterStore.configuration" :key="configuration.key"
        class="flex gap-4 flex-wrap justify-between">
        <div>
          <h3 class="font-medium">{{ configuration.key }}</h3>
          <p>{{ configuration.description }}</p>
        </div>
        <div class="w-96 self-end">
          <BaseTextarea v-if="configuration.type === 'textarea'" :id="configuration.key" :name="configuration.key"
            :placeholder="configuration.placeholder" :initial-value="configuration.value" />
          <BaseSelect v-else-if="configuration.type === 'select'" :id="configuration.key" :name="configuration.key"
            :options="configuration.option" :initial-value="configuration.value" />
          <BaseDatalist v-else-if="configuration.type === 'select-search'" :id="configuration.key"
            :name="configuration.key" :options="configuration.option" :initial-value="configuration.value" />
          <BaseRadio v-else-if="configuration.type === 'radio'" :id="configuration.key" :name="configuration.key"
            :options="configuration.option" :initial-value="configuration.value" />
          <BaseCheckbox v-else-if="configuration.type === 'checkbox'" :id="configuration.key" :name="configuration.key"
            :checked-value="'true'" :unchecked-value="'false'" :initial-value="configuration.value" />
          <BaseInput v-else-if="configuration.type === 'text'" :id="configuration.key" :name="configuration.key"
            :placeholder="configuration.placeholder" :initial-value="configuration.value" />
          <div class="text-end text-xs mt-1">
            {{ configuration.unit }}
          </div>
        </div>
      </BaseBox>
      <div class="flex justify-end">
        <BaseButton v-if="checkPermission('edit_cluster_configuration')" type="submit" :disabled="isSubmitting || !meta.valid">{{ $t('label_save') }}</BaseButton>
      </div>
      <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
    </form>
  </BaseBox>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink, useRoute } from "vue-router";
import router from "@/routes";
import { useClusterStore } from "@/stores/ClusterStore";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { useAuthStore } from "@/stores/AuthStore";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const authStore = useAuthStore();
const { checkPermission } = authStore;

const route = useRoute();
const clusterStore = useClusterStore();
const zoneStore = useZonesStore();

const schema = yup.object({});

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const error = ref(null);
const { addToast } = useToastStore();
const onSubmit = handleSubmit.withControlled(async (values) => {
  const payload = { configuration: [] };
  for (const key in values) {
    if (values[key]) {
      payload.configuration.push({
        key: key,
        value: values[key],
      });
    }
  }
  try {
    await clusterStore.updateConfiguration(payload, route.params.id);
    addToast({
      title: t('text_configurations_update_success'),
      variant: "success",
    });
  } catch (err) {
    addToast({
      title: t('text_configurations_update_failure') + '\n' + err,
      variant: "danger",
    });
  }
});


function getZone() {
  const id = route.params.id;
  if (clusterStore.clusterList.length && zoneStore.zones.length) {
    const cluster = clusterStore.clusterList.filter((cluster) => cluster.id === id);
    const zone = zoneStore.zones.filter((zone) => zone.id === cluster[0].zoneID);
    return zone[0];
  }
}

async function checkIfConfiguration() {
  if (!clusterStore.configuration?.length) {
    await clusterStore.fetchConfiguration(route.params.id);

    if (!clusterStore.configuration?.length) {
      router.push({
        name: "ClusterSinglePageIndex",
        params: { id: route.params.id },
      });
    }
  }
}

checkIfConfiguration();


</script>
