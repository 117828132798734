<template>
  <nav class="flex-1" >
    <ul class="space-y-1 h-full flex flex-col">
      <li class="flex flex-col" v-for="(menuItem, index) in menu" :key="index">

        <SidebarMenuItem
          :to="menuItem.to"
          :hasSubMenu="menuItem.subMenu && menuItem.subMenu.items?.length ? true : false"
          @toggleSubMenu="(id) => toggleSubMenu(id)"
          :id="menuItem.id"
          :subMenuOpen="menuItem.subMenu ? menuItem.subMenu.open : null"
          :label="menuItem.label"
          :parentId="menuItem.id"
        >
          <span v-if="menuItem.icon" class="material-symbols-rounded">
            {{ menuItem.icon }}
          </span>

          <component v-else :is="menuItem.customIconComponent" class="rounded-[3px] w-[24px] h-[24px]" :mode="mode == 'light' ? 'dark' : 'light'" />
          <template v-if="!sidebarCollapsed">{{ menuItem.label }}</template>
        </SidebarMenuItem>
        <SidebarSubMenu
          v-if="menuItem.subMenu && menuItem.subMenu.items?.length && !sidebarCollapsed"
          :open="menuItem.subMenu.open"
          :subMenu="menuItem.subMenu.items"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { useZonesStore } from "@/stores/ZonesStore";
import { useUserStore } from "@/stores/UserStore";
import { storeToRefs } from "pinia";
import { watch, computed } from "vue";
import _ from "lodash";

import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem.vue";
import SidebarSubMenu from "@/components/sidebar//SidebarSubMenu.vue";

const zonesStore = useZonesStore();
const userStore = useUserStore();
const { zones } = storeToRefs(zonesStore);
const { sidebarCollapsed, mode } = storeToRefs(userStore);
import { i18n } from "@/i18n";
const { t } = i18n.global;

const menu = computed(() => [
  {
    id: "home",
    to: "DashboardPage",
    label: "Home",
    icon: "home",
  },
  {
    id: "zones",
    to: "ZonesPage",
    label: t('label_zones'),
    icon: "dns",
    // subMenu: {
    //   open: false,
    //   items: [],
    // },
  },
  {
    id: "monitor",
    to: "MonitorPage",
    label: "Monitor",
    icon: "monitoring",
  },
  {
    id: "pool",
    to: "PoolPage",
    label: "Pool",
    icon: "atr",
  },
  {
    id: "cluster",
    to: "ClusterPage",
    label: "Deep",
    icon: false,
    customIconComponent: 'DeepIcon'
  },
  {
    id: "lory",
    to: "LoryPage",
    label: "Lory",
    icon: false,
    customIconComponent: 'LoryIcon'
  },
]);

watch(zones, () => {
  let index = menu?.value?.findIndex((menuItem) => menuItem.id == "zones");
  if(index === -1 || !menu?.value[index]?.subMenu) return
  menu.value[index].subMenu.items = _.orderBy(zonesStore.zones, 'domain', 'asc');
});

watch(sidebarCollapsed, () => {
  menu.value = menu.value.map((item) => {
    if (item.subMenu) {
      item.subMenu.open = false
    }

    return item;
  })
});

const toggleSubMenu = (id) => {
  let index = menu.value.findIndex((menuItem) => menuItem.id == id);
  if (menu.value[index].subMenu) {
    menu.value[index].subMenu.open = !menu.value[index].subMenu.open;
  }
};
</script>

<style lang="postcss" scoped>
.material-symbols-rounded {
  /* font-variation-settings: "FILL" 1, "wght" 900, "GRAD" 0, "opsz" 24; */
}
</style>
