<template>
  <div class="flex gap-x-2">
    {{ label }}
    <div class="flex gap-x-[2px] relative">
      <div
        v-for="(bar, i) in data"
        :key="i"
        class="w-[6px] min-h-[22px] bg-red-500 tooltip"
        :data-tooltip="`${calculateTooltip(i)} &#10;${bar}%`"
      >
        <div class="bg-green-500" :style="{ height: `${bar}%` }"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from "dayjs";


const props = defineProps({
  label: String,
  data: { type: Array, default: () => [] },
  unit: { 
    type: String, 
    default: "d", 
  },
});
const { data, unit, label } = props;

function calculateDay(index) {
  const currentDate = dayjs();
  const sub = currentDate.subtract(index, "day");
  const day = sub.format("DD-MMM");
  return day;
}

function calculateHour(index) {
  const currentDate = dayjs();
  const sub = currentDate.subtract(index, "hour").minute(0).second(0);
  const day = sub.format("HH:mm");
  return day;
}

function calculateTooltip(index) {
  switch (unit) {
    case "d":
      return calculateDay(index);
    case "h":
      return calculateHour(index);
    default:
      return calculateDay(index);
  }
}

</script>

<style lang="postcss" scoped>
.tooltip {
  &::after {
    display: none;
    content: attr(data-tooltip);
    @apply absolute text-xs font-medium bg-zinc-600 text-white px-1.5 py-0.5 rounded-md;
    background-color: rgba(theme("colors.zinc.800"), 0.7);
    backdrop-filter: blur(3px);
    text-wrap: nowrap;
    bottom: calc(100% + 0.5rem);
    white-space: pre; 
  }

  &:hover {
    &::after {
      display: block;
    }
  }
}
</style>
