<template>
  <h2 class="text-xl font-semibold">Reset credentials</h2>
  <form class="w-full space-y-8" @submit="onSubmit">
    <div class="">
      <BasePasswordField id="password" label="Password" name="userPassword" placeholder="Password">
        <template #label-more>
          <div class="w-1/2 h-2.5 bg-zinc-100 rounded-full overflow-hidden">
            <password-meter :password="values.userPassword" @score="onScore" />
          </div>
        </template>
      </BasePasswordField>
    </div>
    <BasePasswordField id="confirmPassword" label="Confirm password" name="userConfirmPassword"
      placeholder="Confirm password" />
    <BaseButton formnovalidate :disabled="isSubmitting || !meta.valid || score < 0" class="block ml-auto">{{ $t('label_complete_reset') }}
    </BaseButton>
    <!-- <AlertMessage v-if="error || score < 1" status="error" class="text-center text-red-500">{{ $t(error) || $t('validation_message_password_score') }}</AlertMessage> -->
    <AlertMessage v-if="success" status="success" class="text-center">
      {{ $t('text_password_reset_click') }}
      <router-link class="underline underline-offset-2" :to="{ name: 'HomePage' }"
        style="z-index: 5 !important; position: relative;">{{ $t('text_here_to_log_in') }}</router-link>
    </AlertMessage>
  </form>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { i18n } from "@/i18n";
const { t } = i18n.global;
import { useForm } from "vee-validate";

import PasswordMeter from "vue-simple-password-meter";

import { useAuthStore } from "@/stores/AuthStore";
import { RouterLink, useRoute, useRouter } from "vue-router";
import useToastStore from "@/stores/toast";
import {resetPasswordSchema} from "@/validations/schemes/auth";


const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const { addToast } = useToastStore();

const error = ref(false);
const success = ref(false);

const schema = resetPasswordSchema;

const { isSubmitting, handleSubmit, meta, resetForm, values } = useForm({
  validationSchema: schema,
});

const score = ref(null);
const onScore = (payload) => {
  console.log(payload);
  score.value = payload.score;
};

const onSubmit = handleSubmit(async (values) => {
  try {
    error.value = false;
    await authStore.resetCredentials({
      ...values,
      resetToken: route.query.resetToken,
    });
    resetForm();
    success.value = true;
  } catch (err) {
    error.value = err;
  }
});

onBeforeMount(async () => {
  try {
    await await authStore.validateResetToken({ resetToken: route.query.resetToken });
  }
  catch (err) {

    error.value = err;

    addToast({
      title: t('text_reset_session_expired'),
      variant: "danger"
    });

    router.push({ name: "RecoveryPage" });
  }
})

</script>

<style lang="postcss">
.po-password-strength-bar {
  @apply h-full rounded-none mt-0 !important;
}
</style>
