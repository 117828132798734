<template>
	<BaseBox class="space-y-4">
		<h3 class="text-lg font-semibold py-1">{{ $t("label_users") }}</h3>
		<div class="flex items-center justify-between w-full gap-3">
			<SearchBar
				class="w-80 mr-auto"
				@on-search="(value) => (search = value.toLowerCase())" />
			<BaseButton
				v-if="authStore.checkPermission('can_invite_user')"
				size="sm"
				class="block ml-auto"
				@click="handleAddUser"
				>{{ t("label_invite_user") }}</BaseButton
			>
		</div>
		<Table
			:data="rows"
			:searching="isSearching">
			<template #head>
				<tr>
					<th>{{ $t("label_name") }} {{ $t("label_surname") }}</th>
					<th>{{ $t("label_email") }}</th>
					<th>{{ $t("label_phone") }}</th>
					<th class="center">{{ $t("label_status") }}</th>
					<th>{{ $t("label_role") }}</th>
					<th v-if="isAdmin">{{ $t("label_organization") }}</th>
					<th class="center">{{ $t("label_action") }}</th>
				</tr>
			</template>
			<template #body>
				<tr
					v-for="row in rows"
					:key="row._id">
					<td>{{ row.name }} {{ row.surname }}</td>
					<td>{{ row.email }}</td>
					<td>{{ row.phone }}</td>
					<td>
						<div class="flex items-center justify-center">
							<BaseTag
								:variant="getStatus(row).variant"
								size="md"
								:text="getStatus(row).text" />
						</div>
					</td>
					<td>{{ getRoleName(row.role) }}</td>
					<td v-if="isAdmin">{{ getOrganizationName(row.organizationID) }}</td>
					<td class="end">
						<div class="flex items-center justify-center gap-2">
							<BaseButton
								v-if="authStore.checkPermission('*') &&
									userStore.user._id !== row._id &&
									row.active &&
									((row.active && !row.invitation_sent_at) ||
									(row.invitation_sent_at && row.invitation_accepted_at))"
								icon="edit"
								:tooltip="$t('label_edit')"
								size="xs"
								variant="secondary"
								@click="handleAddUser(row._id, true)" />
							<BaseButton
								v-if="authStore.checkPermission('can_invite_user') &&
									userStore.user._id !== row._id &&
									row.active &&
									!row.invitation_accepted_at &&
									row.invitation_sent_at"
								icon="autorenew"
								:tooltip="$t('title_resend_invitation')"
								size="xs"
								variant="secondary"
								@click="handleResendInvitation(row)" />
							<BaseButton
								v-if="userStore.user._id !== row._id"
								icon="delete"
								:tooltip="$t('label_delete')"
								size="xs"
								variant="danger"
								@click="handleUserDelete(row._id)" />
						</div>
					</td>
				</tr>
			</template>
		</Table>
	</BaseBox>
</template>

<script setup>
	import { ref, computed } from "vue";
	import { useAuthStore } from "@/stores/AuthStore";
	import { useUserStore } from "@/stores/UserStore";
	import { useModalStore } from "@/stores/ModalStore";
	import { useIdpStore } from "@/stores/IdpStore";
	import { useOrganizationsStore } from "@/stores/OrganizationsStore";
	import AddNewUser from "../user/AddNewUser.vue";
	import ConfirmModal from "@/components/ui/ConfirmModal.vue";
	import useToastStore from "@/stores/toast";
	import { capitalizeFirst } from "@/utilities/helpers";

	import { i18n } from "@/i18n";
	const { t } = i18n.global;

	const authStore = useAuthStore();
	const userStore = useUserStore();
	const idpStore = useIdpStore();
	const organizationStore = useOrganizationsStore();
	const modalStore = useModalStore();
	const { addToast } = useToastStore();

	const search = ref("");
	const error = ref("");

	const props = defineProps({
		data: {
			type: Array,
			default: () => [],
		},
		isAdmin: {
			type: Boolean,
			default: () => false,
		},
	});

	const isSearching = computed(() => {
		return search.value ? true : false;
	});

	const rows = computed(() => {
		return search.value
			? props.data.filter((row) => {
					return (
						row.name.toLowerCase().includes(search.value.toLowerCase()) ||
						row.surname.toLowerCase().includes(search.value.toLowerCase()) ||
						row.email.toLowerCase().includes(search.value.toLowerCase())
					);
			  })
			: props.data;
	});

	const getRoleName = computed(() => {
		return (slug) => {
			const role = idpStore.roleList.find((role) => role.slug === slug);
			return role ? role.name : slug;
		};
	});

	const getOrganizationName = computed(() => {
		return (orgId) => {
			const org = organizationStore.organizations.find((org) => org._id === orgId);
			return org ? org.name : "-";
		};
	});

	function getStatus(row) {
		const now = new Date();

		if ((row.active && !row.invitation_sent_at) || (row.invitation_sent_at && row.invitation_accepted_at)) {
			return { variant: "success", text: t("text_active") };
		}

		if (row.active && !row.invitation_accepted_at && row.invitation_sent_at) {
			// Check if invitation has expired
			if (!row.invitation_expire_at || new Date(row.invitation_expire_at) > now) {
				return { variant: "warning", text: t("text_status_invited") };
			}
			return { variant: "danger", text: t("text_status_invitation_expired") };
		}

		return { variant: "danger", text: t("text_inactive") };
	}

	function handleAddUser(_id = null, isEditing = false) {
		let initialValues = {};

		if (_id && isEditing) initialValues = props.data.find((row) => row._id == _id);

		modalStore.openModal({
			title: t(isEditing ? "text_edit_user" : "label_invite_user"),
			component: AddNewUser,
			props: {
				initialValues,
				isEditing,
			},
		});
	}

	async function handleUserDelete(userId) {
		error.value = "";
		try {
			await modalStore.openConfirmModal({
				title: t("title_delete_user"),
				component: ConfirmModal,
				props: {
					error,
					content: t("text_delete_delete_user"),
				},
			});

			await userStore.deleteUser(userId);

			addToast({
				title: `${capitalizeFirst(t("label_user"))} ${t("text_delete_generic_success")}`,
				variant: "success",
			});
		} catch (err) {
			error.value = err;
			if (err) {
				addToast({
					title: `${t("text_delete_generic_failure")} ${t("label_user")}`,
					variant: "danger",
					content: error.value || err,
				});
			}
		} finally {
			await modalStore.closeModal();
		}
	}

	async function handleResendInvitation(user) {
		error.value = "";

		try {
			const inviteToResend = {
				name: user.name,
				surname: user.surname,
				email: user.email,
				phone: user.phone,
				role: user.role || "",
				organizationID: user.organizationID || "",
			};

			await modalStore.openConfirmModal({
				title: t("title_resend_invitation"),
				component: ConfirmModal,
				props: {
					error,
					content: `${t("text_resend_invitation")}`,
				},
			});

			await authStore.inviteUser(inviteToResend);
			await userStore.fetchUsers();

			addToast({
				title: `${capitalizeFirst(t("label_user"))} ${t("text_invite_generic_success")}`,
				variant: "success",
			});
		} catch (err) {
			error.value = err;
			if (error.value) {
				addToast({
					title: `${t("text_invite_generic_failure")}`,
					content: error.value || err,
					variant: "danger",
				});
			}
		} finally {
			await modalStore.closeModal();
		}
	}
</script>
