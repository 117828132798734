<template>
	<BaseBox class="space-y-4 p-4" :key="updateKey">
		<h3 class="text-lg font-bold mb-4">{{ $t("title_change_password") }}</h3>
		<form class="space-y-4" @submit="onChangePassword">
			<!-- Old Password -->
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<BasePasswordField
					id="previusPassword"
					:label="$t('label_old_password')"
					name="previusPassword"
					:placeholder="$t('placeholder_old_password')"
					required
					:initialValue="oldPassword"
					/>
			</div>
			<!-- New Password and Confirm Password -->
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<BasePasswordField
					id="userPassword"
					:label="$t('label_new_password')"
					name="userPassword"
					:placeholder="$t('placeholder_new_password')"
					required
					:initialValue="newPassword"
				/>
				<BasePasswordField
					id="userConfirmPassword"
					:label="$t('label_confirm_password')"
					name="userConfirmPassword"
					:placeholder="$t('placeholder_confirm_password')"
					required
					:initialValue="confirmPassword"
				/>
			</div>
			<!-- Save Button -->
			<div class="text-right mt-4">
				<BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t("title_change_password") }}</BaseButton>
			</div>
		</form>
	</BaseBox>
</template>

<script setup>
	import { ref } from "vue";
	import { useForm } from "vee-validate";
	import { useUserStore } from "@/stores/UserStore";
	import useToastStore from "@/stores/toast";
	import {changePasswordSchema} from "@/validations/schemes/auth";
	import { i18n } from "@/i18n";
	import ConfirmModal from "@/components/ui/ConfirmModal.vue";
	import { useModalStore } from "@/stores/ModalStore";

	const { t } = i18n.global;
	const modalStore = useModalStore();
	const userStore = useUserStore();
	const { addToast } = useToastStore();

	const updateKey = ref(0);
 	const oldPassword = ref("");
	const newPassword = ref("");
	const confirmPassword = ref("");
	const error = ref("");

	const schema = changePasswordSchema;

	const { isSubmitting, handleSubmit, meta } = useForm({
		validationSchema: schema,
	});

	const onChangePassword = handleSubmit.withControlled(async (values) => {
		try {
			await modalStore.openConfirmModal({
				title: t('title_password_change'),
				component: ConfirmModal,
				props: {
					error,
					content: t("text_modal_change_password")
				},
			});

			await userStore.updateUserCredentials(values, userStore.user._id);

			addToast({ title: t("text_success_change_password"), variant: "success" });
			window.location.reload();
		} catch (err) {
			if(err.value)
				addToast({ title: t("text_change_password_failure"), content: err, variant: "danger" });
		}
		finally {
			modalStore.closeModal();
		}
	});
</script>
