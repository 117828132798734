<template>
    <div class="flex items-center justify-start gap-2 flex-wrap">
        <template v-for="counter, idx in counters" :key="idx">
            <BaseTag :text="$t(counter.text)" :icon="counter.icon" :variant="counter?.variant"/>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { i18n } from '@/i18n';
const {t} = i18n.global;
const props = defineProps({
    zone: Object,
    withMonitors: { type: Boolean, default: true },
    withPools: { type: Boolean, default: true },
});

const {zone } = props;


function count(type = 'monitor') {
    const res = [];
    const total = zone?.summary?.[type]?.count || 0;

    let unhealthy = "";
    let healthy = "";
    let notSet = "";

    unhealthy = zone?.summary?.[type]?.['unhealthy'] || 0;
    healthy = zone?.summary?.[type]?.['healthy'] || 0;
    notSet = zone?.summary?.[type]?.['not_set'] || 0;

    if(!total) {
        return res
    }
    if(total === unhealthy) {
        res.push(
        {
            text: `text_all_${type}_${type === 'monitor' ? 'unhealthy' : 'down'}`,
            variant: "danger",
            icon: "close"
        }
        );
        return res;
    }
    else if(total === notSet) {
        res.push(
        {
            text: `text_all_${type}_not_active`,
            variant: "secondary",
            icon: "equal"
        }
        );
        return res;
    }
    else if(status && total > 0) {
        res.push(
        {
            text: `text_all_${type}_${type === 'monitor' ? 'healthy' : 'up'}`,
            variant: "success",
            icon: "check"
        }
        );
        return res;
    }
    else {
        if(healthy){
            res.push(
            {
                text:  `${healthy} ${healthy > 1 ? t(`text_${type}s_up`) : t(`text_${type}_up`)}`,
                variant: "success",
                icon: "check"
            })
            ;

        }
        if(unhealthy){
            res.push(
            {
                text:  `${unhealthy} ${unhealthy > 1 ? t(`text_${type}s_down`) : t(`text_${type}_down`)}`,
                variant: "danger",
                icon: "close"
            })
            ;

        }
        return res;
    }
}


const counters = computed( () => {
    return [ ...count(), ...count('pool') ];
});

</script>

<style lang="scss" scoped></style>