<template>
  <BaseBox class="space-y-4">
    <div class="flex justify-between">
      <div class="space-y-4">
        <h2 class="text-2xl font-bold">{{ $t('title_manage_configuration') }} {{ getZone()?.domain ? $t('text_for') : '' }}
          <RouterLink v-if="getZone()" :to="`/zones/${(getZone()?.id)}`"
            class="font-bold text-neptune-500 hover:text-neptune-600 dark:text-neptune-300 dark:hover:text-neptune-400">
            {{ (getZone())?.domain ? (getZone())?.domain : '' }}
          </RouterLink>
        </h2>
        <p>{{ $t('text_choose_file_to_edit') }}</p>
      </div>
      <div>
        <BaseButton class="mt-0 flex-start" size="sm" @click="router.go(-1)"> {{ $t('label_go_back') }} </BaseButton>
      </div>
    </div>
    <div class="flex justify-end items-center">
      <div class="w-60 flex-col gap-3">
        <BaseSelect :value="selectedFile" name="file"
          :options="[{ label: $t('label_choose_file'), value: '', disabled: true }, ...options]"
          @change="(event) => handleOnSelectedFile(event)" :key="`fileInput_${selectKey}`" ref="fileInput"></BaseSelect>
      </div>
    </div>
    <div>
      <VAceEditor v-if="selectedFile" v-model:value="content" class="h-[500px]" :lang="'html' || fileLanguage"
        :print-margin="false" :theme="mode == 'light' ? 'chrome' : 'ambiance'" />
    </div>
    <div v-if="selectedFile" class="flex justify-between items-end">
      <small>{{ lastChange }}</small>
      <BaseButton v-if="checkPermission('edit_cluster_configuration_file')" :disabled="!isContentEdited" size="sm" @click="handleOnSave">{{ $t('label_save') }}</BaseButton>
    </div>
  </BaseBox>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-ambiance";
import { useModalStore } from "@/stores/ModalStore";
import UpdateConfigurationFile from "@/components/clusters/UpdateConfigurationFile.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import useToastStore from "@/stores/toast";
import { useAuthStore } from "@/stores/AuthStore";
import router from "@/routes";
import { useClusterStore } from "@/stores/ClusterStore";
import { useZonesStore } from "@/stores/ZonesStore";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const userStore = useUserStore();
const authStore = useAuthStore();

const { mode } = storeToRefs(userStore);
const { addToast } = useToastStore();
const { checkPermission } = authStore;

const clusterStore = useClusterStore();
const zoneStore = useZonesStore();

const props = defineProps({
  fileList: {
    type: Array,
    required: true,
  },
  lastChange: {
    type: String,
    required: true,
  },
  fileContent: {
    type: String,
    default: "",
  },
  fileLanguage: {
    type: String,
  },
});

const fileInput = ref(null)
const selectKey = ref(0);

const emit = defineEmits(["onSelectedFile", "onSave"]);

const options = computed(() => {
  return props.fileList.map((file) => {
    return {
      label: file.key,
      value: file.key,
    };
  });
});

const selectedFile = ref("");
const previousSelectedFile = ref("");
const content = ref("");

watch(
  () => props.fileContent,
  (value) => (content.value = value)
);

watch(selectedFile, (newValue, oldValue) => {
  previousSelectedFile.value = oldValue
});

const isContentEdited = computed(() => {
  return content.value !== props.fileContent;
});

function handleOnSelectedFile(event) {
  if (isContentEdited.value) {
    fileInput.value.value = selectedFile.value
    addToast({
      title: t('text_save_file_before_out'),
      variant: "warning",
    });

    return;
  }

  selectedFile.value = event.target.value;

  emit("onSelectedFile", selectedFile.value);
  content.value = props.fileContent;
}

const modalStore = useModalStore();
const route = useRoute();

function handleOnSave() {
  modalStore.openModal({
    component: UpdateConfigurationFile,
    props: {
      clusterID: route.params.id,
      fileContent: content.value,
      filename: selectedFile.value,
    },
    title: "Update Configuration File",
  });
}


function getZone() {
  const id = route.params.id;
  if (clusterStore.clusterList.length && zoneStore.zones.length) {
    const cluster = clusterStore.clusterList.filter((cluster) => cluster.id === id);
    const index = zoneStore.zones.findIndex((zone) => zone.id === cluster[0].zoneID);
    return zoneStore.zones[index];
  }
}
</script>
