<template>
  <div class="grid grid-cols-12 gap-y-10 md:gap-x-10">
    <div class="col-span-12 md:col-span-6">
      <PageTitle />
      <p>ID: {{ poolsStore.pool.id }}</p>
      <p>CODE: {{ poolsStore.pool.poolCode }}</p>
    </div>
    <div v-if="Object.keys(poolsStore.pool).length" class="col-span-12 md:col-span-6">
      <BaseBox class="relative" style="overflow: visible;">
        <div class="flex md:flex-wrap items-start justify-between">

          <div class="flex flex-col items-center justify-center gap-2">
            <IconText :title="$t('label_virtual_machines')" :text="poolsStore.pool.vmCount" icon="computer" icon-size="text-5xl">
              {{poolsStore.pool.vmCount }}
            </IconText>
          </div>

          <div class="flex flex-col items-center justify-center gap-2">
            <IconText :title="$t('title_memory')" icon="memory_alt" icon-size="text-5xl">
              {{ convertToHigherUnit(poolsStore.pool.memory_mib, "MB") }}
            </IconText>
            <div v-if="poolsStore.pool.memory_mib > poolsStore.pool.contract.memory_mib" 
                class="bg-red-600 text-white px-3 py-1 rounded-lg font-bold text-xs"
            >
              + {{ ((poolsStore.pool.memory_mib - poolsStore.pool.contract.memory_mib)/1024).toFixed(0) }}GB
            </div>
          </div>
          
          <div class="flex flex-col items-center justify-center gap-2">
            <IconText title="CPU" icon="memory" icon-size="text-5xl">
              {{ poolsStore.pool.vcpu }}
            </IconText>
            <div v-if="poolsStore.pool.vcpu > poolsStore.pool.contract.vcpu" 
                 class="bg-red-600 text-white px-3 py-1 rounded-lg font-bold text-xs"
            >
              + {{ (poolsStore.pool.vcpu - poolsStore.pool.contract.vcpu) }}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center gap-2">
            <IconText title="GHZ" icon="memory" icon-size="text-5xl">
              {{ calculateGHz(poolsStore.pool.vcpu).toFixed(2) }}GHZ
            </IconText>
          </div>
          
          <div class="flex flex-col items-center justify-center gap-2">
            <IconText :title="$t('label_storage')" icon="database" icon-size="text-5xl">
              {{ convertStorageUnit(poolsStore.pool.storage_mib , "GB", "MB") }}GB
            </IconText>
            <div v-if="poolsStore.pool.storage_mib > poolsStore.pool.contract.storage_mib"
              class="bg-red-600 text-white px-3 py-1 rounded-lg font-bold text-xs"
            >
              + {{ convertStorageUnit( poolsStore.pool.storage_mib - poolsStore.pool.contract.storage_mib, "GB", "MB").toFixed()}}GB
            </div>
          </div>

        </div>
        
        <BaseButton
              v-if="authStore.checkPermission('*')"
              icon="edit"
              variant="primary"
              style="position: absolute; top: -10px; right: -10px; margin: 0"
              @click="openEditPoolLimitsModal"/>
      </BaseBox>
    </div>
  </div>
  <VirtualMachine v-if="poolsStore.virtualMachineList" :data="poolsStore.virtualMachineList"
    :pool-id="route.params.id" />
</template>

<script setup>
import { useRoute } from "vue-router";
import { usePoolsStore } from "@/stores/PoolsStore";
import { convertToHigherUnit, convertStorageUnit } from "@/utilities/helpers";
import { useAuthStore } from "@/stores/AuthStore";
import { useModalStore } from "@/stores/ModalStore";
import VirtualMachine from "@/components/pool/VirtualMachine.vue";
import {i18n} from "@/i18n";
import EditResourcesLimits  from "@/components/pool/EditResourcesLimits.vue";
const { t } = i18n.global;

const poolsStore = usePoolsStore();
const authStore = useAuthStore();
const modalStore = useModalStore();
const route = useRoute();

poolsStore.fetchPool(route.params.id);
function calculateGHz(val){
  return val * import.meta.env.VITE_CONVERSION_RATE
}
function openEditPoolLimitsModal() {
		modalStore.openModal({
			title: t("text_edit_pool_limits"),
			component: EditResourcesLimits,
		});
}

</script>
