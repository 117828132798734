import { defineStore } from "pinia";
import { idp } from "@/api/base";

export const useIdpStore = defineStore("IdpStore", {
  // state
  state: () => ({
    roleList: [],
  }),
  // getters
  getters: {},
  // actions
  actions: {
    async fetchRoles() {
      const { roleList } = await idp.getRoles();
      this.roleList = roleList;
    },
  },
});