<template>
  <div class="flex justify-between items-center gap-4">
    <PageTitle class="flex-fill" />
    <div class="w-64" id="searchOrganization" v-if="organizationOptions.length > 0 && checkPermission('*')">
      <BaseSelect
        :label="$t('label_organization')"
        v-model="searchOrganization"
        name="searchOrganization"
        :options="[
          {
            value: '',
            label:  t('label_all'),
          },
          ...organizationOptions,
        ]"
      />
    </div>
  </div>
  <ClusterList v-if="filteredClusters.length" :cluster-list="filteredClusters" />
  <div class="text-center font-bold opacity-60" v-else>
    {{ $t("text_no_clusters_available") }}
  </div>
</template>

<script setup>
import { useClusterStore } from "@/stores/ClusterStore";
import ClusterList from "@/components/clusters/ClusterList.vue";
import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/AuthStore";
const { checkPermission } = useAuthStore();
import { i18n } from "@/i18n";
const { t } = i18n.global;

const clusterStore = useClusterStore();
const organizationsStore = useOrganizationsStore();

const { clusterList } = storeToRefs(clusterStore);

// clusterStore.fetchClusters();
const searchOrganization = ref("");

const organizationOptions = computed(() =>
  organizationsStore.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }))
);

const filteredClusters = computed(() => {
  if (searchOrganization.value === "") {
    return clusterList.value;
  }
  return clusterList.value.filter((monitor) => {
    return monitor.organizationID === searchOrganization.value;
  });
});
</script>
<style lang="postcss" scoped>
#searchOrganization {
  & > .base-select{
    display: flex;
    gap: 0.5rem;
    justify-content: end;
  }
}
</style>