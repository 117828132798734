import * as yup from 'yup';
import { 
  serverPortSchema, 
  nameBaseSchema, 
  cidrAclBaseSchema 
} from './common';


const firewallRuleSchema = yup.object({
  name: nameBaseSchema.required(),
  port: serverPortSchema.required(),
  type: yup.string().required(),
  cidr_acl: cidrAclBaseSchema.required(),
});

const configurationFileSchema = yup.object({
  commitMessage: yup.string().required()
});


export { 
  firewallRuleSchema, 
  configurationFileSchema 
};