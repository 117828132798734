<template>
	<BaseBox class="space-y-4">
		<h3 class="text-lg font-semibold py-1">{{ $t('label_organizations') }}</h3>
		<div class="flex items-center justify-between w-full gap-3">
			<SearchBar class="w-80 mr-auto" @on-search="(value) => (search = value.toLowerCase())" />
			<BaseButton v-if="authStore.checkPermission('create_zone_pool')" size="sm" class="block ml-auto" @click="handleOrganization">{{ $t('label_add_organization') }}</BaseButton>
		</div>
		<Table :data="rows" :searching="isSearching">
			<template #head>
				<tr>
					<th>{{ $t('label_name') }}</th>
					<!-- <th>{{ $t('label_email_alert') }}</th> -->
					<th>{{ $t('label_email_billing') }}</th>
					<th>{{ $t('label_phone') }}</th>
					<th>{{ $t('label_zoho_customer_id') }}</th>
					<th>{{ $t('label_address') }}</th>
					<th>{{ $t('label_zones') }}</th>
					<th>{{ $t('label_action') }}</th>
				</tr>
			</template>
			<template #body>
				<tr v-for="row in rows" :key="row.id">
					<td>{{ row.name }}</td>
					<!-- <td>{{ row.emailAlert }}</td> -->
					<td>{{ row.emailBilling }}</td>
					<td>{{ row.phone }}</td>
					<td>{{ row.zoho_customer_id }}</td>
					<td>{{ generateAddress(row.address) }}</td>
					<td>{{ row.info.zone }}</td>
					<td >
						<div class="flex gap-2">
							<BaseButton
								icon="edit"
								:tooltip="$t('label_edit')"
								size="xs"
								variant="secondary"
								@click="handleOrganization(row._id, true)" />
							<BaseButton
								icon="delete"
								:tooltip="$t('label_delete')"
								size="xs"
								variant="danger"
								@click="handleOrganizationDelete(row._id)" />
						</div>

					</td>
				</tr>
			</template>
		</Table>
	</BaseBox>
</template>

<script setup>
	import { ref, computed } from "vue";
	import { useAuthStore } from "@/stores/AuthStore";
	import { useModalStore } from "@/stores/ModalStore";
	import { useOrganizationsStore } from "@/stores/OrganizationsStore";
	import FormOrganization from "@/components/organization/FormOrganization.vue";
	import ConfirmModal from "@/components/ui/ConfirmModal.vue";
	import useToastStore from "@/stores/toast";
	import { capitalizeFirst } from "@/utilities/helpers";

	import { i18n } from "@/i18n";
	const { t } = i18n.global;

	const { addToast } = useToastStore();
	const authStore = useAuthStore();
	const modalStore = useModalStore();
	const organizationStore = useOrganizationsStore();

	const search = ref("");
	const error = ref("");

	const props = defineProps({
		data: {
			type: Array,
			default: () => [],
		}
	});

	const isSearching = computed(() => {
		return search.value ? true : false;
	});

	const rows = computed(() => {
		return search.value
			? props.data.filter((row) => {
					return row.name.toLowerCase().includes(search.value.toLowerCase()) ||
						//    row.emailAlert.toLowerCase().includes(search.value.toLowerCase()) ||
						   row.emailBilling.toLowerCase().includes(search.value.toLowerCase());
			  })
			: props.data;
	});

	function generateAddress(address) {
		return `${address.street}, ${address.city}, ${address.postalCode}, ${address.state}`;
	}

	function handleOrganization(id, isEditing = false) {
		let initialValues = {};
		if(isEditing) initialValues = props.data.find((row) => row._id == id);
		modalStore.openModal({
			title: t("text_add_new_organization"),
			component: FormOrganization,
			props: {
				initialValues,
				isEditing
			}
		});
	}
	async function handleOrganizationDelete(id) {
		error.value = "";
		try {
			await modalStore.openConfirmModal({
				title: t('title_delete_organization'),
				component: ConfirmModal,
				props: {
					error,
					content: t('text_delete_organization'),
				},
			});

			await organizationStore.deleteOrganization(id);

			addToast({
				title: `${capitalizeFirst(t("text_delete_success"))}`,
				variant: "success",
			});
		} catch (err) {
			error.value = err;
			if(err){
				addToast({
					title: `${capitalizeFirst(t("text_delete_failure"))}`,
					variant: "danger",
					content: error.value || err,
				});
			}
		} finally {
			await modalStore.closeModal();
		}
	}
</script>
