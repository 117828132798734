import * as yup from 'yup';

const mibToGib = yup
                .number()
                .typeError()
                .required()
                //.transform((v) => v / 1024)

const poolContractSchema = (initialValues) => yup.object({
    memory_mib:  mibToGib
                .isGreaterThanOrEqual(initialValues.memory_mib),
    vcpu: yup
          .number()
          .required()
          .typeError()
          .isGreaterThanOrEqual(initialValues.vcpu),
    storage_mib: mibToGib
                .isGreaterThanOrEqual(initialValues.storage_mib),
});

export {
    poolContractSchema
}