import * as yup from 'yup';
import { emailBaseSchema, phoneBaseSchema } from './common';

const userSchema = yup.object({
    name: yup.string().min(4).required(),
    surname: yup.string().min(4).required(),
    email: emailBaseSchema.required(),
    phone: phoneBaseSchema.required(),
    organizationID: yup.string().required(),
    role: yup.string().required(),
});

const userProfileSchema = yup.object({
    name: yup.string().min(4).required(),
    surname: yup.string().min(4).required(),
    email: emailBaseSchema.required(),
    phone: phoneBaseSchema.required(),
});

export {
    userProfileSchema
}

export default userSchema;
