<template>
	<nav>
		<ul class="flex items-center gap-x-5">
			<!-- <li class="hidden md:block">
        <SearchZones />
      </li> -->
			<li>
				<BaseButton @click="switchMode" :tooltip="`${$t('text_switch_to_' + (mode == 'light' ? 'dark' : 'light'))}`" tooltip-position="bottom" :icon="mode == 'light' ? 'dark_mode' : 'light_mode'" variant="secondary"> </BaseButton>
			</li>
			<li>
				<BaseDropdownItem>
					<template #menuitem> {{ $t("label_support") }} </template>
					<template #submenu>
						<!-- <HeaderMenuItem tag="a" href="https://resource.deepstone.cloud/deepstone-api" target="_blank">{{ $t("label_documentation") }}</HeaderMenuItem> -->
						<HeaderMenuItem tag="a" href="https://resource.deepstone.cloud/deepstone-api" target="_blank">{{ $t("label_guide") }} </HeaderMenuItem>
						<HeaderMenuItem tag="a" href="https://support.deepstone.cloud/" target="_blank">{{ $t("label_support") }} </HeaderMenuItem>
					</template>
				</BaseDropdownItem>
			</li>
			<li>
				<BaseDropdownItem>
					<template #menuitem> {{ $t("label_language_name") }} </template>
					<template #submenu>
						<!--        <HeaderMenuItem to="#">English</HeaderMenuItem>
            <HeaderMenuItem disabled to="#">Italian</HeaderMenuItem> -->
						<HeaderMenuItem to="" :active="lang == 'en'" @click.prevent="switchLanguage('en')"> English </HeaderMenuItem>
						<HeaderMenuItem to="" :active="lang == 'it'" @click.prevent="switchLanguage('it')"> Italiano </HeaderMenuItem>
					</template>
				</BaseDropdownItem>
			</li>
			<li>
				<BaseDropdownItem>
					<template #menuitem>
						<div class="flex gap-2 items-center font-semibold">
							<div class="user-initials">
								{{ userInitials }}
							</div>
							<div>
								<div class="flex flex-col justify-start items-start">
									{{ userName }}
									<h3 class="text-xs font-semibold">{{ organizationsStore.currentOrganization?.name }}</h3>
								</div>
							</div>
						</div>
					</template>
					<template #submenu>
						<div class="px-4 py-3 border-zinc-200 dark:border-zinc-600 border-b">
							<div class="text-xs opacity-75">{{ $t("text_my_profile") }}</div>
							<div class="text-sm font-medium">{{ user.email }}</div>
						</div>
						<HeaderMenuItem tag="RouterLink" to="/profile">{{ $t("text_profile_settings") }}</HeaderMenuItem>
						<HeaderMenuItem tag="RouterLink" to="/billing">{{ $t("text_billing") }}</HeaderMenuItem>
						<HeaderMenuItem v-if="!checkPermission('*')" tag="RouterLink" to="/organization">{{ $t("label_organization") }}</HeaderMenuItem>
						<HeaderMenuItem v-if="checkPermission('*')" tag="RouterLink" to="/organization-list">{{ $t("label_organization_list") }}</HeaderMenuItem>
						<HeaderMenuItem v-if="checkPermission('*')" tag="RouterLink" to="/user-list">{{ $t("text_user_list") }}</HeaderMenuItem>
						<HeaderMenuItem to="#" class="border-zinc-200 dark:border-zinc-600 border-t" @click="logout()">{{ $t("label_logout") }} </HeaderMenuItem>
					</template>
				</BaseDropdownItem>
			</li>
		</ul>
	</nav>
</template>

<script setup>
	import { computed } from "vue";

	import { storeToRefs } from "pinia";

	import HeaderMenuItem from "./HeaderMenuItem.vue";
	import BaseDropdownItem from "../ui/BaseDropdownItem.vue";
	import BaseButton from "../ui/BaseButton.vue";
	import { useOrganizationsStore } from "@/stores/OrganizationsStore";
	import { useAuthStore } from "@/stores/AuthStore";
	import { useUserStore } from "@/stores/UserStore";

	const { logout, checkPermission } = useAuthStore();
	const organizationsStore = useOrganizationsStore();
	const userStore = useUserStore();
	const { mode, user } = storeToRefs(userStore);
	const { currentOrganization } = organizationsStore;

	const { lang } = storeToRefs(useAuthStore());

	const userName = computed(() => `${user.value.name} ${user.value.surname}`);

	const userInitials = computed(() => {
		let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

		let initials = [...userName.value.matchAll(rgx)] || [];

		initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();

		return initials;
	});

	function switchMode() {
		userStore.switchMode();
	}

	async function switchLanguage(locale) {
		userStore.setLang(locale);
	}
</script>

<style lang="postcss" scoped>
	.submenu-wrapper {
		@apply flex items-center gap-2;
		cursor: pointer;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: calc(100% + 16px);
			height: calc(100% + 16px);
			z-index: -1;
			@apply rounded-md;
		}

		&:hover {
			&::after {
				background-color: theme("colors.neptune.400" / 7%);
			}
		}
	}

	.user-initials {
		@apply rounded-full h-7 w-7 text-sm flex items-center justify-center font-bold text-white;
		background-color: theme("colors.neptune.400" / 70%);
	}
</style>
