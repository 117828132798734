import * as yup from 'yup';
import { emailBaseSchema } from './common';

const lorySignupSchema =  yup.object({
  lory_mail: emailBaseSchema.required(),
  lory_telegram_id: yup.string().required(),
});

export {
  lorySignupSchema
}