<template>
	<div class="grid grid-cols-12 gap-y-10 md:gap-x-10">
		<div class="col-span-12 md:col-span-6">
			<PageTitle />
		</div>
	</div>
	<OrganizationList :data="organizations" />
</template>

<script async setup>
	import { storeToRefs } from "pinia";
	import OrganizationList from "@/components/organization/OrganizationList.vue";
	import { useOrganizationsStore } from '@/stores/OrganizationsStore';

	const organizationsStore = useOrganizationsStore();
	const { organizations } = storeToRefs(organizationsStore);

</script>
