<template>
  <div
    class="sub-menu text-sm overflow-y-auto"
    :class="{ open: open }"
    @scroll="onScroll"
    ref="scrollable"
  >
    <div
      class="fade top"
      :class="mode"
      :style="scrollPosition < 10 ? `opacity: 0` : ''"
    ></div>
    <ul>
      <li v-for="(subItem, index) in subMenu" :key="index">
        <!-- Da cambiare in caso ci siano altri sub menu oltre a quello delle zone -->
        <RouterLink
          :to="{ name: 'ZoneSinglePageIndex', params: { id: subItem.id } }"
          class="sub-menu-item"
          active-class="active"
          :title="subItem.domain"
          >{{ subItem.domain }}</RouterLink
        >
      </li>
    </ul>
    <div
      class="fade bottom"
      :class="mode"
      :style="scrollPosition > scrollHeight - 10 ? `opacity: 0` : ''"
    ></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/UserStore";

const props = defineProps({
  subMenu: {
    type: Array,
    default: [],
  },
  open: {
    type: Boolean,
    default: false,
  }
});

const scrollable = ref(null);

const scrollPosition = ref(0);
const scrollHeight = ref(0);

watch(
  () => props.open,
  async () => {
    if (scrollHeight.value) return;

    await nextTick()
    scrollHeight.value = scrollable.value.scrollHeight - scrollable.value.offsetHeight;
  }
);

function onScroll(event) {
  scrollPosition.value = event.srcElement.scrollTop;
}

const userStore = useUserStore();
const { mode } = storeToRefs(userStore);
</script>

<style lang="postcss" scoped>
@property --fade {
  syntax: "<color>";
  initial-value: #fff;
  inherits: false;
}

@property --fadeHeight {
  syntax: "<length>";
  initial-value: 30px;
  inherits: false;
}

.sub-menu {
  display: none;
  max-height: 20vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  @apply mb-3;

  .fade {
    content: "";
    width: 100%;
    height: var(--fadeHeight);
    display: block;
    position: sticky;
    left: 0;
    background: linear-gradient(to top, var(--fade), transparent);
    transition: --fade 0.2s, all 0.2s, height 0.5s;
    pointer-events: none;

    &.dark {
      --fade: theme("colors.zinc.800");
    }

    &.top {
      top: 0;
      background: linear-gradient(to bottom, var(--fade), transparent);
    }

    &.bottom {
      bottom: 0;
      background: linear-gradient(to top, var(--fade), transparent);
    }
  }

  ul {
    margin-top: -30px;
    margin-bottom: -30px;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;

      &:first-child {
        @apply mt-3;
      }
    }
  }

  .sub-menu-item {
    font-weight: 500;
    margin-left: calc(1.5rem + 24px);

    &:hover {
      @apply text-zinc-950 dark:text-zinc-300;
    }

    &.active {
      color: theme("colors.neptune.400");
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.open {
    display: block;
  }
}
</style>
