<template>
  <BaseBox v-for="(cluster, idx) in clusterList" :key="cluster.id">
    <p class="deepstone-number">{{ idx % 2 == 0 ? '100' : '95' }}</p>
    <div class="flex gap-x-8">
      <h3 class="font-semibold z-10">{{ cluster.domain }}</h3>
      <div class="flex gap-x-4 ml-auto">
        <AlertMessage v-for="(pop, i) in cluster.popList" :key="pop.code"
          :status="pop?.status === 'healthy' ? 'success' : 'error'" class="text-center p-4">
          <h4 class="relative z-10" :class="pop.status === 'healthy'
            ? 'dark:text-green-400'
            : 'dark:text-red-400'
            ">
            POP #{{ i + 1 }}
          </h4>
          <p class="relative z-10 text-lg text-zinc-700">{{ pop.code }}</p>
          <p class="relative z-10 text-zinc-700 font-normal text-sm">
            {{ pop.local_ip }}
          </p>
          <p class="relative z-10 mt-2">
            {{ (pop.status) ? pop.status.charAt(0).toUpperCase() + pop.status.slice(1) : 'Not Active' }}
          </p>
        </AlertMessage>
      </div>
      <div class="space-y-2">
        <h3 class="font-semibold">{{ $t('title_quality') }}</h3>
        <div>
          <small>{{ $t('text_last_24_global_activity') }}</small>
          <StatusBars :data="[100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]" />
          <small>{{ $t('text_last_30_days_global_activity') }}</small>
          <StatusBars :data="[100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]" />
        </div>
        <div class="flex gap-x-8 items-center">
          <div v-if="cluster.waf" class="flex items-center gap-x-1">
            <span class="material-symbols-outlined text-4xl">
              verified_user
            </span>
            <strong>{{ $t('text_waf_available_desc') }}</strong>
          </div>
          <div v-else class="flex items-center gap-x-1">
            <span class="material-symbols-outlined text-4xl">
              remove_moderator </span><strong>{{ $t('text_waf_unavailable_desc') }}</strong>
          </div>
          <BaseButton v-if="checkPermission('view_cluster_single_page')"  tag="RouterLink" class="inline-block"
            :to="{ name: 'ClusterSinglePageIndex', params: { id: cluster.id } }">{{ $t('label_go_deep') }}</BaseButton>
        </div>
      </div>
    </div>

    <div>
      <a class="hover:underline hover:text-neptune-400" :href="cluster.application_link" target="_blank">
        {{ $t('text_link_to_application') }}
      </a>
    </div>
  </BaseBox>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore';
const authStore = useAuthStore();
const { checkPermission } = authStore;
defineProps({
  clusterList: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="postcss" scoped>
.deepstone-number {
  font-size: 12rem;
  letter-spacing: -0.5rem;
  @apply font-extrabold leading-none text-neptune-100 dark:text-neptune-900 opacity-50 dark:opacity-20 pointer-events-none;
  position: absolute;
  top: -10%;
  left: -2%;
}
</style>
