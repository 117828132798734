<template>
  <ZoneMissing v-if="!isValidating && !zone.configured" @on-check-again="handleCheckAgain" />
  <ManagePool />
  <ManageEntry :recordID="searchID" />
</template>

<script setup>
import { useZonesStore } from "../stores/ZonesStore";

import ZoneMissing from "@/components/zones/ZoneMissing.vue";
import ManagePool from "@/components/zones/ManagePool.vue";
import ManageEntry from "@/components/zones/ManageEntry.vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";
import useToastStore from "@/stores/toast";
import { i18n } from "@/i18n";
const { t } = i18n.global;
const isValidating = ref(true);

onBeforeMount(async () => {
  isValidating.value = true;
  await zoneStore.validateZone(route.params.id);
  isValidating.value = false;
  const props = route.meta.props;
  zone.value = props.zone;
  searchID.value = props.hash;
  isValidating.value = false;
})


onBeforeRouteUpdate(async (to) => {
  isValidating.value = true;
  await zoneStore.validateZone(to.params.id);
  isValidating.value = false;
})

const zoneStore = useZonesStore();
const route = useRoute();
const { addToast } = useToastStore();

const zone = ref({});
const searchID = ref(null);


async function handleCheckAgain() {
  try {
    isValidating.value = true;
    await zoneStore.validateZone(route.params.id);
    isValidating.value = false;
    addToast({
      title: t('text_zone_ns_validation_check_success'),
      variant: "success",
    });
  }
  catch (err) {
    addToast({
      title: err || t('text_zone_ns_validation_check_fail'),
      variant: "danger",
    });
  }

}




</script>
