import * as yup from "yup";
import { 
  emailBaseSchema,
  nameBaseSchema,
  phoneBaseSchema,
  addressBaseSchema
} from "./common";

const organizationSchema = yup.object({
  name: nameBaseSchema.min(4).required(),
  emailAlert: emailBaseSchema
              .required(),
  emailBilling: emailBaseSchema
                .required(),
  phone: phoneBaseSchema.required(),
  address: addressBaseSchema.required(),
  zoho_customer_id: yup.string().min(2).required(),
});


export default organizationSchema;