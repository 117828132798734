<template>
  <form class="space-y-4" @submit="onSubmit">

    <div class="grid grid-cols-12 gap-y-4 md:gap-x-8">
      <!-- ENDPOINTS FORM -->
      <div class="col-span-12 md:col-span-6 space-y-4">
        <!-- NAME -->
        <BaseInput id="name" :label="$t('label_pool_name')" name="name" :placeholder="$t('placeholder_name')" type="text" required />
        <!-- TYPE -->
        <BaseRadio name="type" :options="[
    { label: 'Balancing', value: 'balanced' },
    { label: 'Failover', value: 'failover' },
  ]" @change="onTypeChanged" />
        <!-- DESCRIPTION -->
        <BaseTextarea id="description" :label="$t('label_description')" name="description" :placeholder="$t('placeholder_description')" />
        <!-- ENDPOINTS -->
        <h3 class="text-lg font-semibold">Endpoint</h3>
        <!-- ENDPOINTS LIST -->
        <div class="flex flex-col gap-4" v-for="(field, idx) in fields" :key="field.key">
          <BaseInput v-if="field.value?.id && field.value?.id !== ''" type="hidden" :name="`endpoint[${idx}].id`"
            :value="field.value.id" />

          <!-- EP FIRST LINE FORM -->
          <div class="flex gap-4">
            <!-- EP HOSTNAMNE -->
            <div class="w-4/5">
              <BaseInput :name="`endpoint[${idx}].target`" :label="$t('label_endpoint_address')" placeholder="ip/hostname"
                type="text" required />
            </div>
            <!-- EP DELETE BUTTON -->
            <div class="w-1/5 flex justify-center items-center pt-6">
              <BaseButton v-if="isButtonDeleteVisible(idx)" type="button" icon="delete" :tooltip="$t('label_delete')" size="xs"
                variant="danger" @click="handleDeleteEndpoint(idx)" />
            </div>
          </div>

          <!-- EP SECOND LINE FORM -->
          <div class="flex gap-4">

            <!-- EP NAME -->
            <div class="w-4/5">
              <BaseInput :name="`endpoint[${idx}].name`" :label="$t('label_friendly_name')" :placeholder="$t('placeholder_name')" type="text"
                required />
            </div>

            <!-- EP BALANCED -->
            <div class="w-1/5">
              <!-- EP WEIGHT -->
              <BaseInput v-if="values.type == 'balanced'" :name="`endpoint[${idx}].weight`" :label="$t('label_weight')"
                :placeholder="$t('placeholder_weight')" type="number" min="0" max="100" required />
              <!-- EP MASTER -->
              <BaseCheckbox v-else :name="`endpoint[${idx}].weight`" label="Master" :checked-value="100"
                :unchecked-value="0" :disabled="values.endpoint[idx].weight == '100'" :initial-value="values.endpoint[idx].weight"
                @change="onMasterSelected(idx)" />
            </div>

          </div>

          <hr class="mt-4 w-full col-span-12 border-zinc-200 dark:border-zinc-600"
            :class="{ 'opacity-0': fields.length - 1 == idx }" />
        </div>
        <div class="text-end">
          <BaseButton size="sm" type="button" @click="push(endpointSchema)">{{ $t('label_add_endpoint') }}</BaseButton>
        </div>
      </div>
      <!-- MONITORING FORM -->
      <div class="col-span-12 md:col-span-6 space-y-2">
        <h3 class="text-lg font-semibold">Monitoring</h3>
        <BaseRadio name="monitoring.type" :options="[
    { label: 'PING', value: 'ping' },
    { label: 'HTTP', value: 'http' },
  ]" />
        <div class="flex gap-4">
          <BaseInput id="interval" :label="$t('label_check_interval')" name="monitoring.interval" :placeholder="$t('placeholder_seconds')"
            type="number" min="10" max="600" @blur="(value) =>
    value == null ? (values.monitoring.interval = 0) : null
    " />
          <BaseInput id="checkToFail" :label="$t('label_max_fails')" name="monitoring.checkToFail" :placeholder="$t('placeholder_number')" type="number"
            min="3" max="10" />
        </div>
        <div v-show="values.monitoring?.type == 'http'" class="pt-4 space-y-2">
          <h4 class="text-md font-semibold">{{ $t('text_advanced_settings') }}</h4>
          <BaseInput id="port" :label="$t('label_port')" name="monitoring.port" :placeholder="$t('placeholder_port')" type="number" min="0" />

          <h5 class="text-sm font-semibold">{{ $t('text_keyword_present') }}</h5>
          <div class="flex gap-4">
            <div>
              <div class="text-sm">{{ $t('label_enable') }}</div>
              <BaseSwitch v-model="advancedSettings.needKeywordEnabled" />
            </div>
            <BaseInput v-if="advancedSettings.needKeywordEnabled" id="needKeyword"
              :label="$t('label_present_keywords')" name="monitoring.needKeyword"
              :placeholder="$t('placeholder_keyword')" type="text" />
          </div>
          <h5 class="text-sm font-semibold">{{ $t('text_keyword_not_present') }}</h5>
          <div class="flex gap-4">
            <div>
              <div class="text-sm">{{ $t('label_enable') }}</div>
              <BaseSwitch v-model="advancedSettings.notNeedKeywordEnabled" />
            </div>
            <BaseInput v-if="advancedSettings.notNeedKeywordEnabled" id="notNeedKeyword"
              :label="$t('label_not_present_keywords')" name="monitoring.notNeedKeyword"
              :placeholder="$t('placeholder_keyword')" type="text" />
          </div>


          <h5 class="text-sm font-semibold">{{ $t('label_accepted_states') }}</h5>
          <div class="flex gap-4">
            <div>
              <div class="text-sm">{{ $t('label_enable') }}</div>
              <BaseSwitch v-model="advancedSettings.allowedStatesEnabled" />
            </div>
            <BaseInput v-if="advancedSettings.allowedStatesEnabled" id="allowedStates"
              :label="$t('label_add_status_code_message')" name="monitoring.allowedStates" placeholder="200, 201"
              type="text" />
          </div>

          <h5 class="text-sm font-semibold">{{ $t('text_max_response_time') }}</h5>
          <div class="flex gap-4">
            <div>
              <div class="text-sm">{{ $t('label_enable') }}</div>
              <BaseSwitch v-model="advancedSettings.maxTimeEnabled" />
            </div>
            <BaseInput v-if="advancedSettings.maxTimeEnabled" id="maxTime"
              :label="$t('label_max_time_enabled')" name="monitoring.maxTime"
              placeholder="500" type="text" />
          </div>
        </div>
      </div>

    </div>
    <div class="text-end mt-4">
      <BaseButton v-if="checkPermission('create_zone_pool')" type="submit" formnovalidate :disabled="isSubmitting || !meta.valid">{{ isEditing ? $t('label_update') : $t('label_create')
        }}</BaseButton>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useForm, useFieldArray } from "vee-validate";
import router from "@/routes";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "@/stores/AuthStore";

import { i18n } from "@/i18n";
const { t } = i18n.global;

import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import useToastStore from "@/stores/toast";
import poolSchema from "@/validations/schemes/pool";

const authStore = useAuthStore();
const { checkPermission } = authStore;

const { addToast } = useToastStore();

const props = defineProps({
  initialValues: {
    type: Object,
    default: () => ({
      type: "balanced",
      endpoint: [
        {
          target: "",
          name: "",
          weight: 0,
        },
      ],
      monitoring: {
        type: "ping",
        interval: 20,
        checkToFail: 3,
      },
    }),
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
});

if (props.isEditing && !props.initialValues.monitoring.allowedStates.length) {
  props.initialValues.monitoring.allowedStates = "";
}


const zoneStore = useZonesStore();
const { zone } = storeToRefs(zoneStore);
const { addPool, updatePool } = zoneStore;

const schema = poolSchema

const endpointSchema = {
  target: "",
  name: "",
  weight: 0,
};


const {
  monitoring: { needKeyword, allowedStates, maxTime, notNeedKeyword },
} = props.initialValues;



const advancedSettings = ref({
  needKeywordEnabled: needKeyword ? true : false,
  notNeedKeywordEnabled: notNeedKeyword ? true : false,
  allowedStatesEnabled: allowedStates?.length ? true : false,
  maxTimeEnabled: maxTime ? true : false,
});

const { isSubmitting, handleSubmit, meta, values, setFieldValue, validate } = useForm({
  validationSchema: schema,
  initialValues: props.initialValues,
});

onMounted(async() => {
      if(props.isEditing) await validate();
});

const { remove, push, fields } = useFieldArray("endpoint");

function onTypeChanged() {
  let initials = [];
  let finals = [];
  let merged = [];

  if (values.type === "failover") {
    if (props.initialValues.type === "failover") initials = props.initialValues.endpoint
    merged = [...initials, { ...endpointSchema, weight: 100 }]
    finals = merged.length < 2 ? merged : merged.filter(el => (el.target && el.name));
      if(!finals.length) finals.push({target: '', name: '', weight: 0});
    setFieldValue("endpoint", finals);
  } else {
    if (props.initialValues.type !== "failover") initials = props.initialValues.endpoint
    merged = [...initials, { ...endpointSchema }]
    finals = merged.length < 2 ? merged : merged.filter(el => (el.target && el.name));
    if(!finals.length) finals.push({target: '', name: '', weight: 0})
    setFieldValue("endpoint", finals);
  }
}

function onMasterSelected(idx) {
  values.endpoint.forEach((elm, index) => {
    if (index !== idx) setFieldValue(`endpoint[${index}].weight`, 0);
  });
}

function isButtonDeleteVisible(idx) {
  if (values.endpoint?.length <= 1) return false;
  if (values.type == "failover" && values.endpoint[idx].weight == "100")
    return false;
  return true;
}

const route = useRoute();

const onSubmit = handleSubmit.withControlled(async (values) => {
  if (values.monitoring?.allowedStates)
    values.monitoring.allowedStates =
      values.monitoring.allowedStates.split(",");

  try {
    if (props.isEditing) {
      await updatePool(zone.value.id, props.initialValues.id, { ...values });
    } else {
      await addPool(zone.value.id, { ...values });
    }

    await zoneStore.refreshData(zone.value.id);

    addToast({
      title: props.isEditing ? t('text_update_success') : t('text_create_success'),
      variant: "success",
    });

    router.push({
      name: "ZoneSinglePageIndex",
      params: { id: route.params.id },
    });
  } catch (err) {
    addToast({
      title: props.isEditing ? t('text_update_error') : t('text_creation_error'),
      content: err,
      variant: "danger",
    });
  }
});

const modalStore = useModalStore();

async function handleDeleteEndpoint(idx) {
  const error = ref("");
  try {
    await modalStore.openConfirmModal({
      title: t("title_delete_endpoint"),
      component: ConfirmModal,
      props: {
        error,
        content: t("text_delete_endpoint"),
      },
    });
    remove(idx);
    modalStore.closeModal();
  } catch (err) {
    error.value = err;
  }
}
</script>
