<template>
  <div v-if="!error" class="space-y-4">
    <p v-if="content"> {{ content }} </p>
    <div class="flex gap-x-4 justify-center">
      <BaseButton v-if="showCancelButton" variant="secondary" @click="$emit('closeModal')" :disabled="isLoading"
        >{{ $t( labelCancel || "label_cancel") }}</BaseButton
      >
      <BaseButton v-if="showConfirmButton" @click="modalStore.resolveFunc(true)" :disabled="isLoading">{{ $t( labelConfirm || "label_change_password") }}</BaseButton>
      <LoaderSpinner v-if="isLoading" />
    </div>
  </div>
  <AlertMessage v-else class="mt-1" status="error">{{ error }}</AlertMessage>
</template>

<script setup>
import { onBeforeMount, toRefs } from "vue";
import { useModalStore } from "@/stores/ModalStore";
const modalStore = useModalStore();
import { i18n } from "@/i18n";
const { t } = i18n.global;
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";


const props = defineProps({
  error: String,
  content: String,
  isLoading: Boolean,
  labelConfirm: String,
  labelCancel: String,
  showCancelButton:{ type: Boolean, default: true },
  showConfirmButton:{ type: Boolean, default: true }
});

let { error, content } = toRefs(props);




onBeforeMount(() => {
    if(!content.value) content.value = t("text_confirm_proceed")
})

</script>
