<template>
	<div class="profile-page">
		<div class="flex justify-between items-center mb-4">
			<PageTitle/>
		</div>
		<!-- Profile Settings Section -->
		<ProfileSettingsForm />
		<!-- Password Change Section -->
		<PasswordChangeForm />
	</div>
</template>

<script setup>
	import ProfileSettingsForm from "@/components/user/ProfileSettingsForm.vue";
	import PasswordChangeForm from "@/components/user/PasswordChangeForm.vue";
	import { i18n } from "@/i18n";
import PageTitle from "../components/ui/PageTitle.vue";

	const { t } = i18n.global;

</script>
