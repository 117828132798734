<template>
	<div
		class="base-input"
		:class="{ error: errorMessage }">
		<div
			v-if="label"
			class="flex justify-between items-center mb-1">
			<label
				class="flex-1"
				:for="$attrs.id"
				>{{ label }}
				<span
					v-if="required"
					class="text-red-600 dark:text-red-400 font-medium"
					>*</span
				></label
			>
			<slot name="label-more"></slot>
		</div>
		<div
			class="relative"
			:class="{
				'flex': prepend || isStepBtn,
				'btn-step': isStepBtn,
			}">
			<div
				v-if="prepend"
				class="prepend h-100">
				{{ prepend }}
			</div>
			<div
				v-if="isStepBtn"
				class="h-100">
				<button
					type="button"
					class="px-2 py-1 rounded-l-md h-full"
					:disabled="isMin || disabled"
					@click="decrement">
					-
				</button>
			</div>
			<input
				v-bind="$attrs"
				:type="type"
				:value="value"
				:class="[$attrs.class, prepend ? '!rounded-l-none' : null, isStepBtn ? 'btn-step' : null]"
				:disabled="disabled"
				v-on="validationListeners"
				@input="$emit('update:value', value)" />
			<div
				v-if="suffix"
				
				:class="suffixClass ? suffixClass + ' suffix h-100' : 'suffix h-100' ">
				{{ suffix }}
			</div>
			<div
				v-if="isStepBtn"
				class="h-100">
				<button
					type="button"
					class="px-2 py-1 rounded-r-md h-full"
					:disabled="isMax || disabled"
					@click="increment">
					+
				</button>
			</div>
		</div>
		<span
			v-if="errorMessage"
			class="error-message"
			>{{ errorMessage }}</span
		>
	</div>
</template>

<script>
	// use normal <script> to declare options
	export default {
		inheritAttrs: true,
	};
</script>

<script setup>
	import { useField } from "vee-validate";
	import { computed, ref } from "vue";

	const emit = defineEmits(["update:value"]);

	const props = defineProps({
		type: String,
		name: String,
		label: String,
		initialValue: [String, Number],
		disabled: {
			type: Boolean,
			default: false,
		},
		isStepBtn: {
			type: Boolean,
			default: false,
		},
		min: { type: Number, default: 0 },
		max: { type: Number, default: Infinity },
		step: { type: Number, default: 1 },
		required: Boolean,
		inputValue: {
			type: String,
			required: false,
			default: "",
		},
		prepend: String,
		suffix: String,
		suffixClass: String
	});

	const { value, errorMessage, handleChange, handleBlur } = useField(() => props.name, undefined, {
		validateOnValueUpdate: false,
		initialValue: props.initialValue,
	});

	if (props?.inputValue) {
		value.value = props.inputValue;
	}

	const validationListeners = {
		blur: (evt) => handleBlur(evt, true),
		change: handleChange,
		keyup: handleChange,
		input: (evt) => handleChange(evt, !!errorMessage.value),
	};

	// Increment the value
	const increment = () => {
		const newValue = Math.min(parseInt(value.value) + props.step, props.max);
		updateValue(newValue);
	};

	// Decrement the value
	const decrement = () => {
		const newValue = Math.max(parseInt(value.value) - props.step, props.min);
		updateValue(newValue);
	};

	// Check for boundary states
	const isMin = computed(() => value.value <= props.min);
	const isMax = computed(() => value.value >= props.max);

	// Update the value when the input changes
	const updateValue = (newValue) => {
		let numericValue = parseFloat(newValue);
		if (isNaN(numericValue)) numericValue = props.min;

		if (numericValue < props.min) numericValue = props.min;
		if (numericValue > props.max) numericValue = props.max;

		value.value = numericValue;
		emit("update:value", numericValue);
	};
</script>

<style lang="postcss" scoped>
	.base-input {
		@apply w-full relative;

		label {
			@apply text-sm font-semibold block opacity-75 dark:text-zinc-50;
		}

		input {
			@apply py-1 px-3 border-zinc-200 dark:border-zinc-600 border rounded-md w-full dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50;

			&::placeholder {
				@apply text-zinc-400;
			}

			&:focus,
			&:focus-visible {
				@apply border border-neptune-400 outline-none ring-inset ring-1 ring-neptune-400;
			}
		}

		&.error {
			label {
				@apply text-red-600 dark:text-red-400;
			}

			input,
			.prepend {
				@apply border-red-600 dark:border-red-400;

				&:focus,
				&:focus-visible {
					@apply ring-red-600 dark:ring-red-400;
				}
			}

			.prepend {
				@apply text-red-600 dark:text-red-400;
			}

			.error-message {
				@apply text-xs mt-1 font-medium text-red-600 dark:text-red-400;
			}
		}

		.prepend {
			@apply py-1 px-2 border-zinc-200 dark:border-zinc-600 border-l rounded-l-md border-y dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50 font-bold;
		}

		.suffix {
			@apply py-1 px-2 border-zinc-200 dark:border-zinc-600 border dark:bg-zinc-900 text-zinc-900 dark:text-zinc-50 font-bold;
		}
	}

	.password-icon {
		.material-symbols-rounded {
			@apply text-xl text-zinc-600;
		}
	}

	.btn-step {
		input {
			@apply !rounded-none text-center;
		}

		button {
			@apply text-zinc-600 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-600;

			&:hover {
				@apply bg-zinc-300 dark:bg-zinc-700;
			}

			&:active {
				@apply bg-zinc-700;
			}

			&:disabled {
				@apply bg-zinc-200 text-zinc-700 opacity-50 cursor-not-allowed;
			}
		}
	}
</style>
