<template>
  <form class="w-full space-y-8" @submit="onSubmit">
    <BaseInput
      id="email"
      :label="$t('label_email')"
      name="userEmail"
      :placeholder="$t('placeholder_email')"
      type="email"
    />
    <BasePasswordField
      id="password"
      :label="$t('label_password')"
      name="userPassword"
      :placeholder="$t('placeholder_password')"
    />
    <BaseButton
      formnovalidate
      :disabled="isSubmitting || !meta.valid"
      class="block ml-auto"
      size="md"
      >Login</BaseButton
    >
    <AlertMessage v-if="error" status="error" class="text-center">{{$t("text_login_failed")}}</AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useForm } from "vee-validate";
import router from "@/routes";
import { useAuthStore } from "@/stores/AuthStore";
import { userLoginSchema } from "@/validations/schemes/auth";

const schema = userLoginSchema;

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const error = ref(false);
const authStore = useAuthStore();

const onSubmit = handleSubmit(async (values) => {
  try {
    error.value = false;
    await authStore.login({ ...values });
    router.push({ name: "DashboardPage" });
  } catch (err) {
    error.value = err;
  }
});
</script>
