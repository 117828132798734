import { defineStore } from "pinia";
import router from "@/routes";
import { auth } from "@/api/base";
import { useUserStore } from "./UserStore";

export const useAuthStore = defineStore("AuthStore", {
  // state
  state: () => ({
    auth_token: "",
  }),
  // getters
  getters: {
    isLoggedIn() {
      return this.auth_token.length > 0;
    }
  },
  // actions
  actions: {
    checkPermission(permission) {
      const {user} = useUserStore();
      return ( user?.permissions?.includes('*') || user?.permissions?.includes(permission));
    },
    async login(payload) {
      const data = await auth.login(payload);
      this.auth_token = data.auth_token;
      const { addUser } = useUserStore();
      addUser(data.user);
      //const curr = localStorage.getItem("AuthStore");
      //const toSet = JSON.stringify({ ...JSON.parse(curr), auth_token: this.auth_token });
      //localStorage.setItem("AuthStore", toSet);
    },
    async requestReset(payload) {
      return await auth.requestReset(payload);
    },
    async resetCredentials(payload) {
      return await auth.resetCredentials(payload);
    },
    async logout() {
      await auth.logout();
      this.resetLogout();
    },
    async resetLogout() {
      this.$reset();
      const { deleteUserSession } = useUserStore();
      deleteUserSession();
      router.push({ name: "HomePage" });
      //localStorage.removeItem("AuthStore");
    },
    async validateResetToken(payload) {
      await auth.requestVerifyToken(payload);
    },
    async inviteUser(payload) {
        const data = await auth.inviteUser(payload);
        return data;
    },
  },
  persist: {
    storage: localStorage,
  },
});
