<template>
  <div class="flex justify-between items-center gap-4">
    <PageTitle />
    <SearchBar class="w-80" @on-search="handleSearch" />
    <div class="flex items-center gap-4">
      {{ $t('label_order') }}:
      <BaseSelect class="min-w-32" v-model="sortStatus" @change="handleSearch" name="sortStatus" :options="sortOptions">
      </BaseSelect>
      <div v-if="organizationOptions.length > 0 && checkPermission('*')" class="flex items-center gap-2">
        {{ $t('label_organizations') }}:
        <BaseSelect v-model="searchOrganization" class="min-w-32" name="searchOrganization" :options="[
          {
            value: '',
            label: t('label_all'),
          },
          ...organizationOptions,
        ]"></BaseSelect>
      </div>
    </div>

    <BaseDropdownItem  v-if="checkPermission('*')" class="flex flex-col ml-auto">
      <template #menuitem> {{ $t('label_actions') }} </template>

      <template #submenu>
        <HeaderMenuItem to="#" @click.prevent="handleAddZone">{{ $t('label_add_zone') }}</HeaderMenuItem>
        <HeaderMenuItem to="#" @click.prevent="handleExportZones">{{ $t('label_export_zip') }}</HeaderMenuItem>
        <HeaderMenuItem to="#" @click.prevent="handleImportZones">{{ $t('label_import_zip') }}</HeaderMenuItem>
      </template>
    </BaseDropdownItem>


    <BaseButton v-else size="sm" class="block ml-auto" @click="handleAddZone">{{ $t('label_add_zone') }}</BaseButton>
  </div>

  <template v-if="filteredZones.length">
    <ZoneList :zones="paginatedZones" detailed class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-x-4" />
    <Pagination :items-count="filteredZones.length" :pagination="pagination" />
  </template>
  <div v-else>
    <p class="text-sm text-center font-semibold mb-2">
      {{ $t('text_zone_search_no_results') }}
    </p>
    <p class="text-sm text-center font-semibold">
      {{ $t('text_zone_search_no_results_msg') }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore } from "@/stores/ModalStore";
import { useUserStore } from "@/stores/UserStore";
import useToastStore from "@/stores/toast";

const { addToast } = useToastStore();
import _ from "lodash";
import HeaderMenuItem from "@/components/header/HeaderMenuItem.vue";
import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import ZoneList from "@/components/zones/ZoneList.vue";
import AddNewZone from "@/components/zones/AddNewZone.vue";
import ImportFileZone from "@/components/zones/ImportFileZone.vue";
import { useAuthStore } from "../stores/AuthStore";

import { i18n } from "@/i18n";
const { t } = i18n.global;

const authStore = useAuthStore();
const { checkPermission } = authStore;
const modalStore = useModalStore();
const zonesStore = useZonesStore();
const userStore = useUserStore();
const searchValue = ref("");
const sortStatus = ref("createdAt_desc");
const sortOptions = computed(() => [
  {
    value: "createdAt_desc",
    label: t("label_newest"),
  },
  {
    value: "createdAt_asc",
    label: t("label_oldest"),
  },
  {
    value: "domain_asc",
    label: "A-Z",
  },
  {
    value: "domain_desc",
    label: "Z-A",
  },
]);


const organizationsStore = useOrganizationsStore();

// clusterStore.fetchClusters();
const searchOrganization = ref("");

const organizationOptions = computed(() =>
  organizationsStore.organizations.map(
    (organization) => (
      {
        value: organization.id,
        label: organization.name,
      }))
);

const { user } = storeToRefs(userStore);

const filteredZones = computed(() => {

  let filtered = searchValue.value
    ? zonesStore.zones.filter(({ domain }) =>
      [domain].some((val) =>
        val.toLowerCase().includes(searchValue.value.toLowerCase())
      )
    )
    : zonesStore.zones;

  const sortField = sortStatus.value.split("_")[0];
  const sortDirection = sortStatus.value.split("_")[1];

  if (searchOrganization.value) {
    filtered = filtered.filter(({ organizationID }) => organizationID === searchOrganization.value);
  }

  if (!sortField || !sortDirection) return filtered;

  let sorted = _.orderBy(filtered, [sortField], [sortDirection]);
  return sorted;
});

function handleSearch(value) {
  if (typeof value !== "string") return;
  searchValue.value = value;
}

function handleAddZone() {
  modalStore.openModal({
    title: t('text_add_new_zone'),
    component: AddNewZone,
  });
}

async function handleExportZones() {
  try {
    await zonesStore.exportZones();
    addToast({
      title: t('text_zone_export_success'),
      variant: "success"
    })
  } catch (error) {
    addToast({
      title: error,
      variant: "danger"
    })
  }
}

function handleImportZones() {
  modalStore.openModal({
    title: t('text_import_file'),
    component: ImportFileZone,
    props: {
      extension: "zip",
    },
  });
}

const pagination = reactive({
  currentPage: 1,
  perPage: 14,
  totalPages: computed(() => Math.ceil(filteredZones.value.length / pagination.perPage)),
});

const paginatedZones = computed(() => {
  const { currentPage, perPage } = pagination;
  const start = (currentPage - 1) * perPage;
  const stop = start + perPage;

  return filteredZones.value.slice(start, stop);
});

watch(
  () => pagination.totalPages,
  () => (pagination.currentPage = 1)
);
</script>
