<template>
  <div class="tag" :class="[typeVariant[variant], { icon: icon }, sizeVariant[size]]">
    <span v-if="icon" class="material-symbols-rounded text-white">{{ icon }}</span>
    {{ text }}
  </div>
</template>

<script setup>
const { variant } = defineProps({
  variant: {
    type: String,
    default: "primary",
  },
  text: {
    type: String,
    default: "Text",
  },
  icon: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "sm",
  },
});

const typeVariant = {
  primary: "bg-neptune-400 dark:bg-neptune-600 text-white",
  secondary: "bg-zinc-600 text-white",
  success: "bg-green-500 dark:bg-green-800 text-white",
  warning: "bg-yellow-500 dark:bg-yellow-900 text-white",
  danger: "bg-red-500 dark:bg-red-800 text-white",
};

const sizeVariant = {
  sm: "small",
  md: "medium",
  lg: "large",
};
</script>

<style lang="postcss" scoped>
.tag {
  @apply py-0 px-2 text-xs rounded-full font-semibold inline-flex items-center justify-center gap-[0.125rem];

  &.icon {
    @apply pl-1 pr-2;
  }

  &.medium {
    @apply py-1 rounded-md;
  }

  &.large {
    @apply py-1 rounded-lg text-base gap-2 font-bold;

    .material-symbols-rounded {
      /* font-size: 1.5rem; */
      font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
    }
  }

  .material-symbols-rounded {
    font-size: 1.3rem;
  }
}
</style>
