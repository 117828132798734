import { defineStore } from "pinia";
import { i18n } from "@/i18n";
const { t } = i18n.global;
export const useModalStore = defineStore("ModalStore", {
  // state
  state: () => ({
    title: "",
    component: null,
    props: {},
    size: "",
    resolveFunc: null,
    rejectFunc: null,
    content: null,
  }),
  // getters
  getters: {
    isOpen: (state) => (state.component || state.content ? true : false),
  },
  // actions
  actions: {
    openModal({ title, component, size, props, content }) {
      this.title = title;
      this.component = component;
      this.content = !component ? content : null;
      this.size = size || "md";
      this.props = props;
      document.body.classList.add("overflow-hidden");
    },
    openConfirmModal(options) {
      options = options.title ? options : { ...options, title: t("text_confirm_proceed") };
      this.openModal({...options});
      return new Promise((resolve, reject) => {
        this.resolveFunc = resolve;
        this.rejectFunc = reject;
      });
    },
    closeModal() {
      this.title = "";
      this.component = null;
      this.props = {};
      this.content = "";
      if (this.resolveFunc) this.rejectFunc(false);
      document.body.classList.remove("overflow-hidden");
    },
  },
});
