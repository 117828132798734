<template>
  <div class="flex justify-between">
      <div class="flex gap-2 items-center justify-start">
        <PageTitle />
        <h1 class="font-bold h-full flex items-center text-2xl"> {{ monitorName }} </h1>
      </div>
      <!-- <div>
        <BaseButton class="mt-0 flex-start" size="sm" @click="router.go(-1)"> {{ $t('label_go_back') }} </BaseButton>
      </div> -->
      <BaseButton class="mt-0 flex-start" size="sm" :disabled="!data.length" @click="handleExport"> {{ $t('label_export') }}</BaseButton>
  </div>
  <BaseBox class="space-y-4">
    <Table :key="data.length" :data="data">
        <template #head> 
        <tr class="w-full">
            <th style="width: 20%;">{{ $t('label_before') }}</th>
            <th style="width: 20%;">{{ $t('label_after') }}</th>
            <th>{{ $t('label_date') }}</th>
        </tr>
        </template>
        <template #body>
        <tr v-for="row in data" :key="row.id">
            <td>
              <span v-if="row.from == true" class="material-symbols-outlined text-2xl !text-green-500">
                north_east
              </span>
              <span v-else-if="row.from === false" class="material-symbols-outlined text-2xl !text-red-500">
                south_east
              </span>
              <span v-else class="material-symbols-outlined text-2xl !text-zinc-500">
                power_off
              </span>
            </td>
            <td>
              <span v-if="row.to === true" class="material-symbols-outlined text-2xl !text-green-500">
                north_east
              </span>
              <span v-else-if="row.to === false" class="material-symbols-outlined text-2xl !text-red-500">
                south_east
              </span>
              <span v-else class="material-symbols-outlined text-2xl !text-zinc-500">
                power_off
              </span> 
            </td>
            <td>{{ formatDate(row.createdAt, 'YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
        </template>
    </Table>
  </BaseBox>
</template>

<script setup>
import { useStatisticsStore } from '@/stores/StatisticsStore';
import { useMonitorsStore } from '@/stores/MonitorsStore';
import { computed, onBeforeMount, ref } from 'vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import { useRoute } from "vue-router";

import { formatDate } from '@/utilities/helpers';
import Papa from 'papaparse';
const route = useRoute();
const statisticsStore = useStatisticsStore();

const { fetchMonitorMetrics } = statisticsStore;
const {monitors} = useMonitorsStore();
const monitorName = computed(() => monitors.find((monitor) => monitor.id === route.params.id).name);
const data = ref([]);

onBeforeMount(async () => {
    const { id } = route.params;
    data.value = await fetchMonitorMetrics(id);
    handleExport();
})


const handleExport = () => {
  if(!data.value.length) return;
  const csv = Papa.unparse(data.value.map(({ from, to, createdAt }) => ({ from, to, createdAt: formatDate(createdAt, 'YYYY-MM-DD HH:mm:ss') }))); // Converte i dati in CSV
  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv); // Crea l'URL del CSV
  link.download = 'monitor_metrics.csv'; // Imposta il nome del file
  link.click(); // Avvia il download
};
</script>

<style lang="postscss" scoped>

</style>