import { defineStore } from "pinia";
import { statistics as statisticsApi } from "@/api/base";

export const useStatisticsStore = defineStore("StatisticsStore", {
  // state
  state: () => ({
    statistics: [],
  }),
  // getters
  getters: {},
  // actions
  actions: {
    async fetchStatistics() {
      const data = await statisticsApi.readStatistics();
      return data;
    },
    async fetchMonitorMetrics(monitorID) {
      const {monitorMetrics} = await statisticsApi.readMonitorMetrics(monitorID);
      return monitorMetrics;
    },
  },
});