<template>
  <Table :key="data.length" :data="data">
    <template #head>
      <tr>
        <th style="width: 20%">{{  $t('label_name') }}</th>
        <th style="width: 10%">N° Endpoint</th>
        <th>{{ $t('label_description') }}</th>
        <th style="width: 10%">{{ $t('label_logic') }}</th>
        <th class="center" style="width: 10%">Monitor</th>
        <th class="end" style="width: 10%">{{ $t('label_action') }}</th>
      </tr>
    </template>

    <template #body>
      <tr v-for="row in data" :key="row.id">
        <td>
          <span class="font-semibold">{{ row.name }}</span>
        </td>
        <td>{{ row.endpoint?.length }}</td>
        <td>{{ row.description }}</td>
        <td class="capitalize">{{ row.type }}</td>
        <td class="center">

          <!-- <template v-if="countMonitors(row.id)?.empty === 0 ">
            <BaseTag :text="$t('text_no_monitor')" icon="unknown_med" size="md" />
          </template> -->

          <template v-if="countMonitors(row.id)?.unhealthy > 0">
            <BaseTag :text="`${(countMonitors(row.id))?.unhealthy === 1 ? '1 ' + $t('text_unhealthy') : `${(countMonitors(row.id))?.unhealthy} ${$t('text_unhealthy_multiple')}`}`" variant="danger" size="md" />
          </template>

          <template v-else-if="countMonitors(row.id)?.notSet > 0">
            <BaseTag :text="`${(countMonitors(row.id))?.notSet} ${$t('label_not_set')}`" variant="secondary" size="md" />
          </template>

          <template v-else-if="countMonitors(row.id)?.healthy > 0">
            <BaseTag :text="`${(countMonitors(row.id))?.healthy === 1 ? '1 ' + $t('text_healthy') : `${(countMonitors(row.id))?.healthy} ${$t('text_healthy_multiple')}`}`"  variant="success" size="md" />
          </template>

        </td>
        <td class="end">
          <div class="flex items-center justify-end w-full gap-2">
            <BaseButton icon="edit" v-if="checkPermission('edit_zone_pool')" :to="{ name: 'ZonePoolEditPage', params: { id: zone.id, poolID: row.id } }"
              :tooltip="$t('label_edit')" size="xs" variant="secondary" tag="RouterLink" />
            <BaseButton icon="delete" v-if="checkPermission('delete_zone_pool')" :tooltip="$t('label_delete')" size="xs" variant="danger"
              @click="handlePoolDelete(zone.id, row.id)" />
          </div>
        </td>
      </tr>
    </template>
  </Table>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useZonesStore } from "@/stores/ZonesStore";
import { useModalStore } from "@/stores/ModalStore";
import { useAuthStore } from "@/stores/AuthStore";
import useToastStore from "@/stores/toast";

import ConfirmModal from "@/components/ui/ConfirmModal.vue";

import { i18n } from "@/i18n";
const { t } = i18n.global;

const zoneStore = useZonesStore();
const authStore = useAuthStore();
const { checkPermission } = authStore;
const { zone } = storeToRefs(zoneStore);

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});


const { addToast } = useToastStore();

const modalStore = useModalStore();

async function handlePoolDelete(zoneID, poolID) {
  const error = ref("");
  try {
    await modalStore.openConfirmModal({
      title: t('label_delete_pool'),
      component: ConfirmModal,
      props: {
        error,
        content: t('text_delete_pool'),
      },
    });
    await zoneStore
      .deletePool(zoneID, poolID)
      .then(async (response) => {
        await zoneStore.refreshData(zoneID);
        addToast({
          title: 'Pool ' + t('text_delete_generic_success'),
          variant: "success",
        });
      })
      .catch((error) => {
        addToast({
          title: t('text_delete_generic_failure') + ' pool',
          variant: "danger",
        });
      });
    modalStore.closeModal();
  } catch (err) {
    error.value = err;
  }
}

function countMonitors(id) {
  const counter = {
    healthy: 0,
    unhealthy: 0,
    notSet: 0,
    empty: 0
  }
  zone.value.monitorList
    .forEach(monitor => {
      if (monitor.poolID === id) {
        counter.empty++;
        if (monitor.status === 'unhealthy') counter.unhealthy++;
        if (monitor.status === 'healthy') counter.healthy++;
        if(monitor.status === null) counter.notSet++;
      }
    })
  

  return counter;
}

</script>
