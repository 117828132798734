<template>
	<div class="flex items-center justify-center h-screen px-4">
		<div class="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl">
			<div>
				<h1 class="text-4xl mb-3">{{ t("text_billing_title") }}</h1>
				<h1 class="text-2xl py-0">
					{{ t("text_billing_subtitle") }}
				</h1>
				<p>{{ t("text_billing_paragraph") }}</p>
			</div>
			<div class="flex items-center md:justify-center">
				<BaseButton tag="a" href="https://billing.deepstone.cloud" target="_blank">{{ t("label_billing_cta") }}</BaseButton>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { i18n } from "@/i18n";
	const { t } = i18n.global;
</script>
