import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as parser from 'tld-extract';
import ip from 'ip';
import isCidr from "is-cidr";
import { translate as t, existTranslation as te } from "@/i18n";
import punycode from 'punycode';

dayjs.extend(relativeTime);

/**
 * Converts a value from a given unit to a higher unit.
 * @param {number} value - The value to be converted.
 * @param {string} unit - The unit of the value (B, KB, MB, GB, TB).
 * @param {boolean} [includeUnit=true] - Whether to include the unit in the returned value.
 * @returns {string} - The converted value with the corresponding unit.
 */
export function convertToHigherUnit(value, unit, includeUnit = true) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let index = units.indexOf(unit.toUpperCase());
  let result = value;
  while (result >= 1024 && index < units.length - 1) {
    result /= 1024;
    index++;
  }
  return includeUnit ? `${result.toFixed(2)}${units[index]}` : result.toFixed(2);
}

export function convertStorageUnit(value, toUnit, fromUnit) {
    const units = ["B", "KB", "MB", "GB", "TB", "PB"];
    const fromIndex = units.indexOf(fromUnit.toUpperCase());
    const toIndex = units.indexOf(toUnit.toUpperCase());

    if (fromIndex === -1 || toIndex === -1) {
        throw new Error("Unità non valida. Usa una delle seguenti: " + units.join(", "));
    }

    const difference = fromIndex - toIndex;
    return parseInt(value * Math.pow(1024, difference));
}

/**
 * Formats a timestamp into a specified format.
 * @param {number} timestamp - The timestamp to be formatted.
 * @param {string} format - The format to be used (default: "DD/MM/YYYY").
 * @returns {string} - The formatted date.
 */
export function formatDate(timestamp, format = "DD/MM/YYYY") {
  const date = dayjs(timestamp);
  return date.format(format);
}

/**
 * Calculates the time passed since a given date.
 * @param {number} date - The date to calculate the time passed from.
 * @returns {string} - The time passed in a human-readable format.
 */
export function timePassed(date, unit = "") {
  const now = dayjs();
  const pastDate = now.subtract(date, unit);
  const parsed = pastDate.fromNow();
  return parsed;
}



/**
 * Flattens the given object into a single-level object.
 *
 * @param {Object} obj - The object to be flattened
 * @param {string} [parentKey=''] - The parent key for nested object properties
 * @return {Object} The flattened object
 */
export function flattenObject(obj, parentKey = '') {
  let flattenedObject = {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        Object.assign(flattenedObject, flattenObject(obj[key], newKey));
      } else {
        flattenedObject[newKey] = obj[key];
      }
    }
  }

  return flattenedObject;
};


/**
 * Validates a domain by parsing the host and checking for a subdomain.
 *
 * @param {string} dom - the domain to be validated
 * @return {boolean} true if the domain is valid, false otherwise
 */
export function validateDomain(dom) {
  try {
    const regEx = new RegExp(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/);
    const isValid = regEx.test(dom);

    if (!isValid) return {
      staus: false,
      reason: t('validation_error_bad_format_input')
    };

    const { sub, domain, tld } = parser.parse_host(dom);

    if (sub || !domain) return {
      status: false,
      reason: 'validation_error_second_level_domain'
    }

    return {
      status: true
    };
  }
  catch (error) {
    return {
      status: false,
      reason: 'validation_error_invalid_domain'
    }
  }
}



export function isV6(ipString) {

  try {
    return ip.isV6Format(ipString);
  }
  catch (error) {
    return false;
  }
}
export function isV4(ipString) {

  try {
     if(ip.isV4Format(ipString)){
        const parts = ipString.split('.').map(Number);
        return parts.length === 4 && parts.every(part => part >= 0 && part <= 255);
     }
     else return false;
  }
  catch (error) {
    return false;
  }
}
export function isV4Format(ipString) {

  try {
     if(ip.isV4Format(ipString)){
        return true
     }
     else return false;
  }
  catch (error) {
    return false;
  }
}

/**
 * Checks if the given IP string is a valid CIDR notation.
 *
 * @param {string} ipString - The IP string to be checked.
 * @return {boolean} Returns true if the given IP string is a valid CIDR notation, false otherwise.
 */
export function isCIDR(ipString, v6 = false) {
  try {
    const res = isCidr(ipString);
    return v6 ? res === 6 : res === 4;
  }
  catch (error) {
    return false;
  }
}



/**
 * Check if the given IP string is a valid IPv4 or CIDR.
 *
 * @param {string} ipString - The IP string to be validated
 * @return {object} Object with status indicating validation result and reason for any failure
 */
export function isValid(ipString) {
  let reason = 'validation_error_invalid_ipv4_cidr';

  const is4 = isV4(ipString);
  const is_cidr = isCIDR(ipString);

  if (!is4 && !is_cidr) {
    return {
      status: false,
      reason
    };
  }

  if (!is4) {
    return {
      status: true,
      reason: 'validation_error_invalid_ipv4'
    };
  }
  if (!is_cidr) {
    return {
      status: true,
      reason: 'validation_error_invalid_cidr'
    };
  }

  return {
    status: true,
  };
}

export function validateIPList(ipListString) {
  // Split della stringa in un array di indirizzi IP
  const ipArray = ipListString.split(',');

  let trovato = false ;
  let i = 0;
  let validated = null;
  while (!trovato && i < ipArray.length) {
    validated = isValid(ipArray[i].trim());
    if(!validated.status) trovato = true;
    i++;
  }

  return validated;
}


export async function base64ToBlob(base64String, type) {

  const binaryString = atob(base64String);

  // Convert binary string to array buffer
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  // Create Blob from array buffer
  const blob = new Blob([arrayBuffer], { type: type || 'application/octet-stream' });

  return blob;
}


export  function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}



export function getIpAsArray(row, isV6 = false) {
    if(!row?.nics) return null;
    const nicsCodes = Object.keys(row.nics);
    const result = new Set();
    for (let i = 0; i < nicsCodes.length; i++) {
        const nicCode = nicsCodes[i];
        const nic = row.nics[nicCode];
        const ip = nic.backing[isV6 ? "ipV6" : "ipV4"];
        if (ip) {
            result.add(ip);
        }
    }

    return Array.from(result);

}


export function getIpAsStrings(row, isV6 = false){
    if(!row.nics) return null;
    const nicsCodes = Object.keys(row.nics);
    const result = new Set();
    for (let i = 0; i < nicsCodes.length; i++) {
        const nicCode = nicsCodes[i];
        const nic = row.nics[nicCode];
        const ip = nic.backing[isV6 ? "ipV6" : "ipV4"];
        if (ip) {
            result.add(ip);
        }
    }

    return Array.from(result).join(", ");
}



export function getTotalCapacity(row){
    if(!row.disks) return 0;
    const disks = Object.keys(row.disks);
    let total = 0;
    for (let i = 0; i < disks.length; i++) {
        const diskCode = disks[i];
        const disk = row.disks[diskCode]
        total += disk.capacity;
    }

    return convertToHigherUnit(total, "B")
}



export function extractErrorString (desc, isHtml = false) {
    let errMessage = '';
    try {
      desc = JSON.parse(desc);
    }
    catch (error) {
      errMessage = desc;
    }
    if(typeof desc === 'object') {

      const erroredFields = desc;

      Object.entries(erroredFields).forEach(([key, value]) => {
        const replacedKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
        const translatedKey = te('label_' + replacedKey) ? t('label_' + replacedKey) : null;
        const newKey = translatedKey || key;
        if(translatedKey) delete erroredFields[key];
        return erroredFields[newKey] = t(value);
      });

      if(isHtml){
        errMessage = Object.entries(erroredFields).map(([key, value]) => `${key}: ${value}`).join("<br>");
        return { object: erroredFields, message: errMessage }
      }
      else return { message: erroredFields};

    }
    else {
      errMessage = t(desc) ;
    }
    return { message: errMessage };
}


/**
 * Verifies if the given hostname is valid according to the rules defined in
 * RFC 1034 and RFC 1123.
 *
 * @param {string} hostname the hostname to check
 * @return {boolean} true if the hostname is valid, false otherwise
 */
export function isValidHostname(hostname) {
  const hostnameRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.){1,126}[a-zA-Z0-9-]{1,63}$/;
  if (hostname.length > 253) {
    return false;
  }
  return hostnameRegex.test(hostname);
}


export function sanitizeEmail(value){
    let decodedValue = value;
    try {
      const [localPart, domain] = value.split('@');
      const decodedDomain = punycode.toUnicode(domain); // Decodifica solo il dominio
      decodedValue = `${localPart}@${decodedDomain}`;
    } catch (e) {
      // Ignora errori di decodifica, il valore sarà quello originale
    }
    return decodedValue
}