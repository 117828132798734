<template>
	<BaseBox class="space-y-4 mb-4">
		<h3 class="text-lg font-bold mb-4">{{ $t("title_account") }}</h3>
		<form class="space-y-4" @submit="onUpdateProfile">
			<!-- Name and Surname -->
			<div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<BaseInput id="name" :label="$t('label_name')" name="name" :placeholder="$t('placeholder_name')" type="text" required />
				<BaseInput id="surname" :label="$t('label_surname')" name="surname" :placeholder="$t('placeholder_surname')" type="text" required />
			</div>

			<!-- Email and Phone -->
			<div-- class="grid grid-cols-1 sm:grid-cols-2 gap-4">
				<BaseInput id="email" :label="$t('label_email')" name="email" :placeholder="$t('placeholder_email')" type="email" required />
				<BaseInput id="phone" :label="$t('label_phone')" name="phone" :placeholder="$t('placeholder_phone')" type="string" required />
			</div-->
			<div class="text-right mt-4">
				<BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t("label_save") }}</BaseButton>
			</div>
		</form>
	</BaseBox>
</template>

<script setup>
	import {  onMounted, ref } from "vue";
	import { storeToRefs } from "pinia";
	import { useForm } from "vee-validate";
	import useToastStore from "@/stores/toast";
	import { i18n } from "@/i18n";
	const { t } = i18n.global;

	const { addToast } = useToastStore();

	import { useUserStore } from "@/stores/UserStore";
	const userStore = useUserStore();
	const { user } = storeToRefs(userStore);

	import { userProfileSchema } from "@/validations/schemes/user";

	const schema = userProfileSchema;

	const { isSubmitting, handleSubmit, meta, validate } = useForm({
		validationSchema: schema,
		initialValues: {
			name: user.value.name,
			surname: user.value.surname,
			email: user.value.email,
			phone: user.value.phone,
		},
	});

	onMounted(async () => {
		await validate(); // Triggera la validazione iniziale
	});



	const onUpdateProfile = handleSubmit.withControlled(async (values) => {
		const error = ref("");
		try {
			const userToUpdate = {
				// ...schema.cast(values), // Ensure `phone` is cast to string
				...values,
				organizationID: user.value.organizationID,
				role: user.value.role
			};
			await userStore.updateUser(userToUpdate, user.value._id);
			addToast({
				title: t("text_success_update_profile"),
				variant: "success",
			});
			window.location.reload();
		} catch (err) {
			addToast({
				title: t("text_update_profile_failure"),
				content: error.value || err,
				variant: "danger",
			});
		}
	});
</script>
