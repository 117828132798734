<template>
  <form class="space-y-4" @submit="onSubmit">
    <BaseInput id="domain" :label="$t('label_domain')" name="domain" :placeholder="$t('placeholder_domain_with_example')" type="text" required />
    <div class="text-end mt-4">
      <BaseButton mode="aggressive" size="sm">{{ $t("label_create") }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ $te(error) ? $t(error) : error }}</AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useForm } from "vee-validate";
import { useZonesStore } from "@/stores/ZonesStore";
import useToastStore from "@/stores/toast";
const { addToast } = useToastStore();
import { i18n } from "@/i18n";
import zoneSchema from "@/validations/schemes/zone";
const { t } = i18n.global;


const schema = zoneSchema;


const { handleSubmit } = useForm({
  validationSchema: schema,
});

const emit = defineEmits(["closeModal"]);
const error = ref("");
const { createZone } = useZonesStore();

const onSubmit = handleSubmit.withControlled(async (values) => {
  try {
    await createZone(values);
    emit("closeModal");
    addToast({
      title: t('text_add_success_zone'),
      variant: "success",
    });
  } catch (err) {
    error.value = err;
    addToast({
      title: t('text_add_failure_zone'),
      content: err,
      variant: "danger",
    });
  }
});
</script>
