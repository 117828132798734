import * as yup from 'yup';
import { validateIPList, validateDomain, isV4, isV6, isCIDR, isValidHostname, isV4Format } from '@/utilities/helpers';
import { translate as t  } from "@/i18n";



yup.addMethod(yup.string, 'customEmail', function () {
  return this.test('custom-email', 'a', function (value) {
    const { path, createError } = this;
    const emailRegex =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = emailRegex.test(value); 
    return (
      !value || // Permetti valore vuoto se opzionale
       isValid|| // Valida con la regex
      createError({ path, message: t('validation_error_invalid_email') }) // Ritorna errore
    );
  });
});


yup.addMethod(yup.string, "validateIPList", function (errorMessage) {
    return this.test(`test-iplist`, errorMessage, function (value) {
        const { path, createError } = this;
        const { status, reason } = validateIPList(value);
        return (
            status ||
            createError({ path, message: t(reason)})
        );
    });
});


yup.addMethod(yup.string, "validateDomain", function (errorMessage) {
    return this.test(`test-domain`, errorMessage, function (value) {
        const { path, createError } = this;
        const {status, reason} = validateDomain(value);
        return (
            status||
            createError({ path, message: t(reason) || errorMessage })
        );
    });
});

yup.addMethod(yup.string, "isValidIPv4", function (errorMessage) {
    return this.test(`test-ipv4`, errorMessage, function (value) {
        const { path, createError } = this;
        const validation = isV4(value);
        return (
            validation ||
            createError({ path, message: t('validation_error_invalid_ipv4') })
        );
    });
});


yup.addMethod(yup.string, "passwordMatch", function (equalsTo) {
    return this.test(`test-password`, equalsTo, function (value) {
        const { path, createError } = this;
        //console.log(equalsTo, value);
        const validation = !!this.parent[path] && this.parent[path] === this.parent[equalsTo]
        return (
            validation ||
            createError({ path, message: t('validation_error_password_match') })
        );
    });
});


yup.addMethod(yup.string, "isValidIPv6", function (errorMessage) {
    return this.test(`test-ipv4`, errorMessage, function (value) {
        const { path, createError } = this;
        const validation = isV6(value);
        return (
            validation ||
            createError({ path, message: t('validation_error_invalid_ipv6') })
        );
    });
});

yup.addMethod(yup.number, "isGreaterThanOrEqual", function (existingValue, errorMessage) {
    errorMessage = t("validation_error_greater_or_equal_than_existing");
    return this.test({
        name: "is-greater-than-or-equal",
        message: errorMessage,
        test(value) {
            return value >= (existingValue ?? 0);
        },
    });
});



yup.addMethod(yup.string, "validateDnsRecordValue", function (errorMessage) {
    return this.test(`validate-dns-record-value`, errorMessage, function (value) {
        const { path, createError, parent } = this;

        // Ottieni il valore di "type" dal contesto del genitore
        const type = parent.type;

        let isValid = false;
        let errorCode = t("validation_error_invalid_value");

        // Applica la logica basata sul tipo
        if (["POOL", "SRV"].includes(type)) {
            isValid = !!value; // Valore richiesto
            if (!isValid) errorCode = t("validation_error_required");
        }

        if (["TXT", "SPF"].includes(type)) {
            isValid = !!value && value.length <= 256; // Max 256 caratteri
            if (!isValid) errorCode = `${t("validation_error_max_string", { max: 256 })}`;
        }

        if (type === "A") {
            isValid = yup.string().isValidIPv4().isValidSync(value); // Valida IPv4
            if (!isValid) errorCode = t("validation_error_invalid_ipv4");
        }

        if (type === "AAAA") {
            isValid = yup.string().isValidIPv6().isValidSync(value); // Valida IPv6
            if (!isValid) errorCode = t("validation_error_invalid_ipv6");
        }

        if (type === "CNAME") {
            isValid = !!value && /^(?!.*\.\.)[a-zA-Z0-9_.-]+\.$/.test(value) && value.length <= 250; // RegEx + Max 250
            if (!isValid) errorCode = t("validation_error_invalid_cname");
        }

        if (type === "MX") {
            isValid = !!value && /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) && value.length <= 250; // RegEx + Max 250
            if (!isValid) errorCode = t("validation_error_invalid_mx_value");
        }

        return (
            isValid ||
            createError({ path, message: errorCode })
        );
    });
});


yup.addMethod(yup.string, "validateDnsRecordKey", function (errorMessage) {
    return this.test(`validate-dns-record-key`, errorMessage, function (value) {
        const { path, createError, parent } = this;

        // Ottieni il valore di "type" dal contesto del genitore
        const type = parent.type;

        let isValid = true; // Considera valido di default
        let errorCode = null;


        if (type === "SRV") {
            if (!/^(?:[a-z0-9_.-]+)$/.test(value)) {
                errorCode = t("validation_error_invalid_srv_key");
                isValid = false;
            }
        }
        else {
            if(/\s/.test(value)) {
                errorCode = t("validation_error_no_spaces");
                isValid = false;
            }
        }

        return (
            isValid ||
            createError({ path, message: errorCode })
        );
    });
});

function requiredBySrv(errorMessage) {
    return this.test('is-required-if-srv', errorMessage, function (value) {
        const {  path, createError } = this; // Contesto Yup (this)
        const { type } = this.parent;
        if (type === 'SRV' && (value === undefined || value === null)) {
            const errorCode = errorMessage || t("validation_error_required");
            return createError({ path, message: errorCode });
        }
        return true;
    });
}

yup.addMethod(yup.number, "requiredBySrv", requiredBySrv);
yup.addMethod(yup.string, "requiredBySrv", requiredBySrv);


yup.addMethod(yup.string, "isHostnameOrIp", function (errorMessage) {
    return this.test(`is-hostname-or-ip`, errorMessage, function (value) {
        const { path, createError } = this;
        let errorCode = t("validation_error_required"); // Errore di default
        let isValid = false;

        if(value){


            const is4Format = isV4Format(value);
            //const is6 = isV6(value);

            if(!is4Format){
                isValid = isValidHostname(value);
                errorCode = t("validation_error_invalid_hostname");
            }
            else {
                const isCidr = isCIDR(value);
                const is4 = isV4(value);
                isValid = is4 || isCidr;
                if(!is4 && !isCidr) errorCode = t("validation_error_invalid_ipv4_cidr");
                else if(is4) errorCode = t("validation_error_invalid_ipv4");
                else if(isCidr) errorCode = t("validation_error_invalid_cidr");
            }

        }

        return (
            isValid ||
            createError({ path, message: errorCode })
        );
    });
});

// Digits validation with minimum length, eg. phone numeber
yup.addMethod(yup.string, "digitsWithMinLength", function (minLength) {
	return this.test(
		"is-valid-digits",
		() => t("validation_error_invalid_field"),
		(value) => !value || /^[\d]*$/.test(value)
	).test(
        "min-digits-length",
        () => `${t("validation_error_min_string")} ${minLength}`,
        (value) => !value || value.length >= minLength
    );
});
yup.addMethod(yup.string, "stringWithMinValue", function (minValue) {
	return this.test(
		"is-valid-digits",
		() => t("validation_error_invalid_field"),
		(value) => !value || /^[\d]*$/.test(value)
	).test(
        "min-digits-value",
        () => `${t("validation_error_min_value")} ${minValue}`,
        (value) => {
            return !value || !(parseInt(value) < minValue)
        }
    );
});

yup.addMethod(yup.string, "stringWithMaxValue", function (maxValue) {
	return this.test(
		"is-valid-digits",
		() => t("validation_error_invalid_field"),
		(value) => !value || /^[\d]*$/.test(value)
	).test(
        "max-digits-value",
        () => `${t("validation_error_max_value")} ${maxValue}`,
        (value) => {
            return !value || !(parseInt(value) > maxValue)
        }
    );
});

