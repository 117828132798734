import * as yup from 'yup';
import '../custom_methods';
import { sanitizeEmail } from '../../utilities/helpers';

const serverPortSchema = yup
    .number()
    .min(0)
    .max(6553)
    .typeError()

const emailBaseSchema = yup
    .string()
    .transform((v, o) => sanitizeEmail(o))
    .customEmail()

const passwordBaseSchema = yup
    .string()
    .min(8)
    .matches(/[a-zA-Z]/)
    .matches(/\d/)
    .matches(/^[a-zA-Z0-9]*$/)

const nameBaseSchema = yup
            .string()
            .min(3);

const cidrAclBaseSchema = yup
    .string()
    .validateIPList()


const descriptionBaseSchema = yup
            .string()
            .max(256);

const phoneBaseSchema = yup
            .string()
            .matches(/^\+?[0-9]\d{1,14}$/)
            .min(6);

const streetBaseSchema = yup
            .string()
            .min(3);

const cityBaseSchema = yup
            .string()
            .min(3)

const postalCodeBaseSchema = yup
            .string()
            .min(4)
            .max(6)
            .required();

const stateBaseSchema = yup
          .string()
          .length(2)
          .matches(/^[A-Z]+$/)
          .required();

const addressBaseSchema = yup.object({
    street: streetBaseSchema,
    city: cityBaseSchema,
    postalCode: postalCodeBaseSchema,
    state: stateBaseSchema
});

const domainBaseSchema = yup
    .string()
    .validateDomain()

const hostnameOrIpBaseSchema = yup
    .string()
    .isHostnameOrIp();

export {
    serverPortSchema,
    emailBaseSchema,
    passwordBaseSchema,
    nameBaseSchema,
    descriptionBaseSchema,
    cidrAclBaseSchema,
    phoneBaseSchema,
    stateBaseSchema,
    streetBaseSchema,
    cityBaseSchema,
    postalCodeBaseSchema,
    addressBaseSchema,
    domainBaseSchema,
    hostnameOrIpBaseSchema
}