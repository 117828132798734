<template>
	<BaseBox class="col-span-12">
		<h3 class="text-lg font-semibold mb-2">Monitoring</h3>
		<div class="grid grid-cols-2 gap-4">
			<div v-for="({graph, title}) in graphs" :key="graph" :class="{'p-3': true, 'col-span-2': graph === 'networkUsage' }">
				<div class="font-medium text-md w-full mb-2">{{ title }}</div>
				<KibanaChart
					:title="title"
					:url="getUrl(graph)"
				/>
			</div>
		</div>
	</BaseBox>
</template>

<script setup>
	import KibanaChart from "@/components/pool/KibanaChart.vue";
	import { useUserStore } from "@/stores/UserStore";
	import { storeToRefs } from "pinia";
	import { useVirtualMachineStore } from "@/stores/VirtualMachineStore";
	import { translate as t } from "@/i18n";

	const graphs = [
		{title: t('label_graph_load_average'), graph: "loadAverage"},
		{title: t('label_graph_memory_usage'), graph: "memoryUsage"},
		{title: t('label_graph_cpu_usage'), graph: "cpuUsage"},
		{title: t('label_graph_disk_usage'), graph: "diskUsage"},
		{title: t('label_graph_network_usage'), graph: "networkUsage"},
	];

	const userStore = useUserStore();
	const virtualMachineStore = useVirtualMachineStore();

	const { mode } = storeToRefs(userStore);
	const { summary: virtualMachine } = storeToRefs(virtualMachineStore);

	const to_date = Date.now(); // Timestamp corrente in millisecondi
	const from_date = to_date - 3 * 60 * 60 * 1000; 

	const urlMapID = {
		"loadAverage": import.meta.env.VITE_VM_GRAFANA_LOAD_AVERAGE_PANEL_ID,
		"memoryUsage": import.meta.env.VITE_VM_GRAFANA_MEMORY_USAGE_PANEL_ID,
		"cpuUsage": import.meta.env.VITE_VM_GRAFANA_CPU_USAGE_PANEL_ID,
		"diskUsage": import.meta.env.VITE_VM_GRAFANA_DISK_USAGE_PANEL_ID,
		"networkUsage": import.meta.env.VITE_VM_GRAFANA_NETWORK_USAGE_PANEL_ID,
	}

	function getUrl(graph){
		if(!virtualMachine.value){
			return;
		}
		const values = {
			from_date,
			to_date,
			vm_name: virtualMachine.value.name,
			panel_id: urlMapID[graph],
			theme: mode.value
		};

		return replacePlaceholders(values);
	}

	function replacePlaceholders(values) {
		const template = import.meta.env.VITE_VM_GRAFANA_DASHBOARD_PANELS;
		return template.replace(/{{(.*?)}}/g, (match, key) => {
			return values[key] !== undefined ? values[key] : match;
		});
	}

</script>

<style lang="postcss" scoped>
	.box {
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
</style>
