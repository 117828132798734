<template>
  <div class="lory-page">
    <div class="flex justify-between items-center mb-4">
      <PageTitle />
      <BaseButton
        v-if="checkPermission('access_to_lory')"
        tag="a"
        href="/"
        target="_blank"
        >{{ $t('label_open_lory') }}</BaseButton
      >
    </div>
    <p class="mb-2">
      {{ $t('text_lory_description') }}
    </p>

    <h3 class="text-lg font-bold mb-2">{{ $t('title_configuration') }}</h3>

    <form class="space-y-4" @submit="onSubmit">
      <div class="flex space-x-4">
        <BaseInput
          id="lory_mail"
          :label="$t('label_lory_email')"
          name="lory_mail"
          placeholder="$t('label_lory_email')"
          type="email"
          required
          :initialValue="user.lory_mail || user.email"
        />
        <BaseInput
          id="lory_telegram_id"
          :label="$t('label_lory_telegram_id')"
          name="lory_telegram_id"
          :placeholder="$t('label_lory_telegram_id')"
          type="text"
          required
          prepend="@"
          :initialValue="user.lory_telegram_id"
        />
      </div>
      <div class="text-end mt-4">
        <BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t('label_save') }}</BaseButton>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useForm } from "vee-validate";
import useToastStore from "@/stores/toast";
import ConfirmModal from "@/components/ui/ConfirmModal.vue";
import { useModalStore } from "@/stores/ModalStore";
import { i18n } from "@/i18n";
const { t } = i18n.global;

const modalStore = useModalStore();
const { addToast } = useToastStore();

import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();
const { checkPermission } = authStore;

import { useUserStore } from "@/stores/UserStore";
import { lorySignupSchema } from "../validations/schemes/lory";
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const schema = lorySignupSchema

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit.withControlled(async (values) => {
  const error = ref("");
  try {


    if(user.value.lory_token){
      await modalStore.openConfirmModal({
        title: t('title_update_lory_credentials'),
        component: ConfirmModal,
        props: {
          error,
          content: t('text_update_lory_credentials'),
        },
      });
    } 
 
    await userStore.updateUserLoryCredentials(values, user.value._id); 
    await modalStore.closeModal();
    addToast({
      title: t('text_save_lory_configuration_success'),
      variant: "success",
    });
  } catch (err) {
    if(err)
      addToast({
        title: t('text_save_lory_configuration_failure'),
        content: error.value || err,
        variant: "danger",
      });
    }
});
</script>
