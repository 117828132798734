<template>
  <ul v-if="zones">
    <li v-for="zone in zones" :key="zone.id" :id="zone.id" class="block h-full">
      <RouterLink :to="{ name: 'ZoneSinglePageIndex', params: { id: zone.id } }" :title="zone.domain">
        <div class="zone-card grid grid-cols-3 h-full basis-1/3">
          <div class="flex flex-col items-start justify-between">
              <div class="mb-3 flex items-center justify-between">
                <h3 class="text-lg font-semibold">{{ zone.domain }}</h3>
              </div>
              <ZoneMonitorCounters :zone="zone" />
          </div>
          <div class="flex flex-col items-end justify-between gap-4 col-span-2">
              <div v-if="detailed" class="font-medium text-sm">
                {{ zone?.summary?.records }}
                {{ zone?.summary?.records > 1 ? "entries" : "entry" }}
              </div>
              <BaseBox v-if="zone?.stats" class="col-span-12 w-full max-h-20">
                <div class="w-full h-full">
                    <BaseChart 
                      :data="zone?.stats" 
                      type="Line" 
                      label="Requests" 
                      :options="chartOptions"
                    />
                </div>
              </BaseBox>
          </div>
        </div>
      </RouterLink>
    </li>
  </ul>
</template>

<script setup>
import { RouterLink } from "vue-router";
import ZoneMonitorCounters from "@/components/zones/ZoneMonitorCounters.vue";

defineProps({
  zones: Array,
  detailed: {
    type: Boolean,
    default: false,
  },
});


const chartOptions = {
  responsive: true, 
  maintainAspectRatio: false, 
  plugins:{
    legend: { 
      display: false
    }
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    }
  }
}

</script>

<style lang="postcss" scoped>
.zone-card {
  @apply rounded-xl border border-zinc-200 p-3 dark:border-zinc-600 dark:bg-zinc-800;
  transition: all .2s;

  &:hover {
    background-color: rgba(theme("colors.neptune.400"), 0.05);
    border-color: rgba(theme("colors.neptune.400"), 0.4);
    box-shadow: inset 0 0 0 1px rgba(theme("colors.neptune.400"), 0.4);
  }
}
</style>
