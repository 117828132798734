<template>
  <BaseBox class="grid grid-cols-12 gap-y-4 md:gap-x-4">
    <div class="col-span-12 md:col-span-12">
      <div class="flex items-center justify-between w-full gap-3">
        <h3 class="text-lg font-semibold py-1">{{  $t('text_manage_records_title') }}</h3>
        <BaseButton v-if="!isFormVisible" size="sm" :variant="!isFormVisible ? 'primary' : 'secondary'"
          class="block ml-auto" @click="isFormVisible = true">{{ $t('label_add_record') }}</BaseButton>
      </div>
    </div>
    <div v-if="isFormVisible && checkPermission('create_zone_record')" class="col-span-12">
      <ZoneAddRecord @close-zone-add-record-form="isFormVisible = false" :recordID="recordID" />
      <hr class="mt-4 w-full col-span-12 border-zinc-200 dark:border-zinc-600" />
    </div>
    <div class="col-span-12 space-y-4">
      <div class="flex justify-start items-center w-full gap-5">
        <SearchBar class="w-80" @on-search="(value) => (search = value.toLowerCase())" :inputValue="searchVal" />
        <div class="filter-wrapper" v-if="recordList.length > 1">
          <BaseSelect id="type" name="type" :options="typeOptions" v-model="dataFilters.type"
            :key="`filters_type_${filtersKey}`" />
          <span class="material-symbols-rounded" @click="resetFilters(['type'])" v-if="dataFilters.type">close</span>
        </div>
        <div class="filter-wrapper" v-if="recordList.length > 1">
          <BaseSelect id="monitorStatus" name="monitorStatus" :options="monitorStatusOptions"
            v-model="dataFilters.monitorStatus" :key="`filters_monitorStatus_${filtersKey}`" />
          <span class="material-symbols-rounded" @click="resetFilters(['monitorStatus'])"
            v-if="dataFilters.monitorStatus">close</span>
        </div>
      </div>
      <ZoneRecordTable :data="records" :searching="isSearching" :filtering="isFiltering" :key="zone.id + records.length" />
    </div>
  </BaseBox>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { storeToRefs } from "pinia";

import ZoneRecordTable from "@/components/zones/ZoneRecordTable.vue";
import ZoneAddRecord from "@/components/zones/ZoneAddRecord.vue";

import { useZonesStore } from "@/stores/ZonesStore";
import { useAuthStore } from "@/stores/AuthStore";

import { i18n } from "@/i18n";
const { t } = i18n.global;

const zoneStore = useZonesStore();
const authStore = useAuthStore();

const { checkPermission } = authStore;
const { recordList, zone } = storeToRefs(zoneStore);

const isFormVisible = ref(false);

const searchVal = ref("");

const search = computed({
  get: () => { return searchVal.value },
  set: (value) => (searchVal.value = value),
});

const props = defineProps({
  recordID: {
    type: String,
    required: false,
    default: ''
  },
});

const isSearching = computed(() => {
  return search.value ? true : false;
});

const dataFilters = ref({
  type: null,
  monitorStatus: null,
});

const isFiltering = computed(() => {
  return dataFilters.value.type || dataFilters.value.monitorStatus ? true : false;
});

const records = computed(() => {

  let data = [...recordList.value];

  if (search.value) {
    data = data.filter(
      (record) =>
        record.key.toLowerCase().includes(search.value) ||
        (record.value?.poolName
          ? record.value.poolName.toLowerCase().includes(search.value)
          : record.value.toLowerCase().includes(search.value))
    );
  }

  if(dataFilters.value.monitorStatus) {
    data = data.filter((r) => r.monitor).filter((record) => { 
      if(dataFilters.value.monitorStatus == "not_active") return !record.monitor.status;
      return record.monitor.status == dataFilters.value.monitorStatus; 
    });
 }

  if (dataFilters.value.type) {
    data = data.filter((record) => record.type == dataFilters.value.type);
  }

  if (props?.recordID && search.value) {
    data = data.filter((record) => record.id == props.recordID)
  }

  return data;
});



const typeOptions = computed(() => {
  let options = [
    { value: "A", label: "A" },
    { value: "AAAA", label: "AAAA" },
    { value: "CNAME", label: "CNAME" },
    { value: "MX", label: "MX" },
    { value: "SRV", label: "SRV" },
    { value: "NS", label: "NS" },
    { value: "PTR", label: "PTR" },
    { value: "TXT", label: "TXT" },
    { value: "POOL", label: "POOL" },
    { value: "SPF", label: "SPF" },
  ];

  // if (recordList.value.length) {
  //   options = options.filter((option) =>
  //     recordList.value.some((record) => record.type == option.value)
  //   );
  // }

  options.unshift({ label: "Type", disabled: true });

  return options;
});

const monitorStatusOptions = computed(() => {
  let options = [
    { value: 'not_active', label: t('label_not_active') },
    { value: "unhealthy", label: t("label_unhealthy") },
    { value: "healthy", label: t("label_healthy") },
  ];

  options.unshift({ label: "Monitor status", disabled: true });

  return options;
});

const filtersKey = ref(0);

function resetFilters(filters) {
  filters.forEach((filter) => {
    dataFilters.value[filter] = null;
    filtersKey.value++;
  });
}

onBeforeMount(() => {
  if (props.recordID) {
    const found = records.value.filter((record) => record.id == props.recordID);
    search.value = found[0].key;
  }
});

function canRecordHaveMonitor(record){
  return ['A', 'AAAA', 'CNAME', 'PTR', 'POOL'].includes(record.type);
}

</script>

<style lang="postcss" scoped>
.filter-wrapper {
  @apply w-60 relative;

  .material-symbols-rounded {
    @apply absolute bg-red-100 text-red-700 rounded-full p-[0.125rem] text-lg leading-none cursor-pointer right-7;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
