<template>
	<div class="grid grid-cols-12 gap-y-10 md:gap-x-10">
		<div class="col-span-12 md:col-span-6">
			<PageTitle />
		</div>
	</div>
	<UsersList v-if="checkPermission('*')" :data="usersList" :isAdmin="true" />
</template>

<script async setup>
	import { useUserStore } from "../stores/UserStore";
	import { onMounted } from "vue";
	import { storeToRefs } from "pinia";
	import UsersList from "@/components/user/UsersList.vue";
	import { useAuthStore } from '@/stores/AuthStore';

	const authStore = useAuthStore();
	const userStore = useUserStore();

	const { checkPermission } = authStore;
	const { usersList } = storeToRefs(userStore);

	onMounted(async () => {
		await userStore.fetchUsers();
	});

</script>
