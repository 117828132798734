import { createI18n } from "vue-i18n";
import { translation } from "@/api/base";
import "./validations/messages";
import en from "./locales/en.json";
import it from "./locales/it.json";

const translationsFallbackFiles = {
  en,
  it
}

// Funzione per ottenere le traduzioni dall'API
async function fetchTranslations(locale) {
    const { translations } = await translation.readTranslations(locale);
    return translations; // Fallback a oggetto vuoto
}



// Dopo aver caricato le traduzioni, configura i messaggi di Yup
export async function loadTranslations(locale) {
  if (!locale) {
    const fromStorage = localStorage.getItem("UserStore");
    if (fromStorage) {
      const { lang } = JSON.parse(fromStorage);
      if (lang) {
        locale = lang;
      } else {
        locale = "en";
      }
    } else {
      locale = "en";
    }
  }

  let translations = null;
  try {
    translations = await fetchTranslations(locale);
  } 
  catch (error) {
    console.error('Failed to load translations:', error);
    translations = translationsFallbackFiles[locale];
  }
  i18n.global.mergeLocaleMessage(locale, translations);
  i18n.global.locale.value = locale;

  return locale;
}

// Helper per ottenere traduzioni dinamiche
export const translate = (...args) => {
  return i18n.global.t(...args);
};

export const existTranslation = (...args) => {
  return i18n.global.te(...args);
};


// Configurazione di i18n
export const i18n = createI18n({
    locale: 'en', // Lingua predefinita
    legacy: false, // Utilizza Composition API
    messages: {}, // Nessun messaggio iniziale
    fallbackWarn: false,
    missingWarn: false,
    warnHtmlMessage: false
});


( async () => await loadTranslations())();