<template>
  <form class="space-y-4" @submit="onSubmit">
    <BaseInput id="name" :label="$t('label_rule_name')" name="name" :placeholder="$t('placeholder_rule_name')" type="text" required />
    <BaseInput id="port" :label="$t('label_port')" name="port" :placeholder="$t('placeholder_port')" type="number" min="23" required />
    <BaseRadio id="type" name="type" label="Type" :options="[
    { label: 'TCP', value: 'TCP' },
    { label: 'UDP', value: 'UDP' },
  ]" required />
    <BaseInput id="cidr_acl" :label="$t('label_ip_comma_separated')" name="cidr_acl" placeholder="0.0.0.0" type="text" required />
    <div class="text-end mt-4">
      <BaseButton size="sm" :disabled="isSubmitting || !meta.valid">{{ $t('label_create') }}</BaseButton>
    </div>
    <AlertMessage v-if="error" status="error">{{ error }}</AlertMessage>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { useForm } from "vee-validate";
import { useClusterStore } from "@/stores/ClusterStore";
import { firewallRuleSchema } from "@/validations/schemes/cluster";
const emit = defineEmits(["closeModal"]);

const error = ref("");
const clusterStore = useClusterStore();

const schema = firewallRuleSchema;

const { isSubmitting, handleSubmit, meta } = useForm({
  validationSchema: schema,
});

const onSubmit = handleSubmit.withControlled(async (values) => {
  try {
    await clusterStore.createFirewallRule(values, clusterStore.cluster.id);
    emit("closeModal");
  } catch (err) {
    error.value = err;
  }
});
</script>
