import BaseService from "../../BaseService";

export default class User extends BaseService {
	async updateCredentials(payload, id) {
		const { data } = await this._apiClient.post(`/${this._resource}/me/credentials`, payload);
		return data;
	}

	async updateLoryCredentials(payload, id) {
		const { data } = await this._apiClient.post(`/${this._resource}/${id}/lory-credentials`, payload);
		return data;
	}
}
