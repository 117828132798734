import { defineStore } from "pinia";
import { organization } from "@/api/base";
import { useUserStore } from "./UserStore";

export const useOrganizationsStore = defineStore("OrganizationsStore", {
	// state
	state: () => ({
		organizations: [],
	}),
	// getters
	getters: {
		currentOrganization:(state) => {
			const { user } = useUserStore();
			return state.organizations.find((organization) => organization.id === user.organizationID);
		}
	},
	// actions
	actions: {
		async fetchOrganizations() {
			const { organizationList } = await organization.readAll();
			this.organizations = organizationList;
		},
		async deleteOrganization(id) {
			const result = await organization.delete(id);
			if (result) {
				await this.fetchOrganizations();
			}
		},
		async createOrganization(organizationData) {
			const result = await organization.create(organizationData);
			if (result) {
				await this.fetchOrganizations();
			}
		},
		async updateOrganization(id, organizationData) {
			const result = await organization.update(organizationData, id);
			if (result) {
				await this.fetchOrganizations();
			}
		},
	},
});
