<template>
  <div
    class="app-wrapper gap-5 bg-white dark:bg-zinc-900 text-zinc-700 dark:text-zinc-50 min-h-screen"
  >
    <AppHeader ref="headerRef" class="header" />
    <AppSidebar class="sidebar" :header-height="headerHeight"></AppSidebar>
    <main
      class="flex-1 pl-5 pr-5 relative z-0 mx-auto space-y-5 pb-5"
      :class="[userStore.sidebarCollapsed ? 'ml-20' : 'ml-64', initialized ? '' : 'flex items-center justify-center']"
      :style="`min-height: calc(100vh - ${headerHeight}px - 1.25rem - 1px)`"
    >
      <template v-if="initialized">
        <slot></slot>
      </template>
      <LoaderSpinner v-else />
    </main>
    <BaseModal />
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from "vue";
import { onMounted } from "vue";
import { useZonesStore } from "@/stores/ZonesStore";
import { useUserStore } from "@/stores/UserStore";
import { useMonitorsStore } from "@/stores/MonitorsStore";
import { usePoolsStore } from "@/stores/PoolsStore";
import { useClusterStore } from "@/stores/ClusterStore";
import { useOrganizationsStore } from "@/stores/OrganizationsStore";
import { useIdpStore } from "@/stores/IdpStore";

import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";
import AppSidebar from "@/components/sidebar/AppSidebar.vue";
import AppHeader from "@/components/header/AppHeader.vue";

const { fetchZones } = useZonesStore();
const { fetchMonitors } = useMonitorsStore();
const { fetchClusters } = useClusterStore();
const { fetchPools } = usePoolsStore();
const { fetchOrganizations } = useOrganizationsStore();
const { fetchRoles } = useIdpStore();

const userStore = useUserStore();

const initialized = ref(false);

async function init() {
  initialized.value = false;
  await fetchZones();
  await fetchMonitors();
  await fetchPools();
  await fetchClusters();
  await fetchOrganizations();
  await fetchRoles();
  initialized.value = true;
}

onBeforeMount(async () => {
  await init();
})

const headerRef = ref(null);

const headerHeight = computed(() => {
  return headerRef?.value?.$el?.clientHeight;
});

onMounted(() => {
  if (!userStore.mode) {
    userStore.setDarkMode();
  } else if (userStore.mode == "dark") {
    userStore.setDarkMode();
  } else {
    userStore.setLightMode();
  }
});
</script>

<style lang="postcss">
.app-wrapper {
  transition: background-color 0.2s;
  /* position: fixed; */
  /* width: 100vw; */

  .sidebar {
    height: 100vh;
    z-index: 1;

    @apply fixed top-0 w-64 p-5 rounded-none border-zinc-200 dark:border-zinc-600 border-r;
  }

  .header {
    @apply top-0 sticky mb-5 border-zinc-200 border-b;
  }

  main {
    transition: all 0.2s;
    /* overflow: scroll; */
    /* height: calc(100vh - 6rem); */

    /* &::-webkit-scrollbar { */
    /* display: none; */
    /* } */

    /* min-height: calc(100vh - ) */

    * {
      transition: all 0.2s;
    }
  }
}

body {
  @apply dark:bg-zinc-900;
  transition: background-color 0.2s;
}
</style>
