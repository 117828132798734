<template>
  <div class="absolute flex items-center justify-center w-full h-full top-0 left-0 pl-12 pr-12">
    <div class="flex flex-col gap-6 w-full">
      <div class="flex flex-col gap-4 justify-between items-center">
          <h1 class="text-5xl">{{$t('text_dashboard_welcome')}} {{ user.name }}</h1>
          <h2 class="text-2xl"> {{ $t('text_dashboard_desc') }} </h2>
      </div>
      <div class="flex gap-4 justify-between">
        <BaseBox class="flex flex-col items-center justify-between gap-6 w-[25%]">
          <h1 class="text-4xl">{{ $t('label_zones') }}</h1>
          <p class="text-l text-center">{{ $t('page_title_zones_desc') }}</p>
          <BaseButton @click="router.push({ name: 'ZonesPage' })">{{ $t('label_zones') }}</BaseButton>
        </BaseBox>
        <BaseBox class="flex flex-col items-center justify-between gap-6 w-[25%]">
          <h1 class="text-4xl">{{ $t('label_monitor') }}</h1>
          <p class="text-l text-center">{{ $t('page_title_monitor_desc') }}</p>
          <BaseButton @click="router.push({ name: 'MonitorPage' })">{{ $t('label_monitor') }}</BaseButton>
        </BaseBox>
        <BaseBox class="flex flex-col items-center justify-between gap-6 w-[25%]">
          <h1 class="text-4xl">Cloud</h1>
          <p class="text-l text-center">{{ $t('label_cloud_desc') }}</p>
          <BaseButton @click="router.push({ name: 'PoolPage' })">{{ $t('label_cloud') }}</BaseButton>
        </BaseBox>
        <BaseBox class="flex flex-col items-center justify-between gap-6 w-[25%]">
          <h1 class="text-4xl">Deep</h1>
          <p class="text-l text-center">{{ $t('page_title_deep_desc') }}</p>
          <BaseButton @click="router.push({ name: 'ClusterPage' })">{{ $t('label_deep') }}</BaseButton>
        </BaseBox>
      </div>
      <div class="flex gap-2 align-center justify-center">
          <span :class="{'material-symbols-rounded text-4xl': true,
                        'text-green-500 dark:text-green-400': monitorsStore.getTotalUnhealthyMonitors <= 0,
                        'text-red-500 dark:text-red-400': monitorsStore.getTotalUnhealthyMonitors > 0
                      }">
          {{monitorsStore.getTotalUnhealthyMonitors > 0 ? 'cancel' : 'check_circle'}}
          </span>
          <div :class="{'flex items-center justify-center text-center': true,
                        'text-green-500 dark:text-green-400': getTotalUnhealthyMonitors <= 0,
                        'text-red-500 dark:text-red-400': getTotalUnhealthyMonitors > 0
                      }">
            {{getTotalUnhealthyMonitors > 0 ? $t('text_monitor_with_errors') : $t('text_all_monitor_healthy')}}
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useMonitorsStore } from "@/stores/MonitorsStore";
import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const monitorsStore = useMonitorsStore();
const { getTotalUnhealthyMonitors, monitors } = monitorsStore;

import router from "@/routes";
</script>
