import { defineStore } from "pinia";
import { pool } from "@/api/base";

export const usePoolsStore = defineStore("PoolsStore", {
  // state
  state: () => ({
    pools: [],
    pool: {},
    virtualMachineList: [],
  }),
  // getters
  getters: {},
  // actions
  actions: {
    async fetchPools() {
      const { resourcePoolList } = await pool.readAll();
      this.pools = resourcePoolList;
    },
    async fetchPool(id) {
      const { resourcePool, virtualMachineList } = await pool.read(id);
      this.pool = resourcePool;
      this.virtualMachineList = virtualMachineList;
    },
    async updatePool(id, payload) {
      const { resourcePool } = await pool.update(id, payload);
      this.pool = resourcePool;
    },
  },
});
