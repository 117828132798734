import * as yup from 'yup';
import { toTypedSchema } from "@vee-validate/yup";
import { domainBaseSchema } from './common';


const importFileZoneSchema = toTypedSchema(
  yup.object({
    file: yup.mixed().required(),
  })
);

const zoneSchema = yup.object({
  domain: domainBaseSchema.required(),
});


export {
    importFileZoneSchema
}

export default zoneSchema;